//!!MENU
header{
	z-index: 50;
	position: relative;
	width: 100%;
	transition: $time;
	transform: translateZ(0);
	perspective: 999999px;
	position: fixed;
	top: 0;
	background-color: $main-bg-colour;
}

#site-logo{
	display: block;
	height: auto;
	max-width: 100%;
	width: 188.045px;
	position: relative;
	margin: 13px 0 2px;

	@media (max-width: $b2){
		width: 111.2px;
		margin: 13px 0 2px;
	}

	img{
		display: block;
		width: 100%;
		height: auto;
		margin-left:0;
	}
}

.menu-top-outer{
	margin: 0;
	padding: 17px 0 0;

	@media (max-width: $b2){
		padding: 17px 0 23px;
	}

	.wrapper{
		justify-content: space-between;
		flex-wrap: nowrap;
		width: 88%;

		@media (max-width: $b2){
			position: static;
		}
	}

	.contact{
		letter-spacing: -1px;

		@media (max-width: $b2){
			width: 155px;
			text-align: right;
			position: relative;
		}

		span{

			@media (max-width: $b2){
				@include font-size(12);
				@include inline;
				padding-left: 2px;
			}

			a{
				text-decoration: none;
				color: $colour;
				font-weight: 900;

				@media (max-width: $b2){
					@include font-size(12);
				}

				&:hover,
				&:focus{
					color: $red-dark;
				}
			}
		}

		.btn{
			padding: 7px 29px;
			margin-left: 23px;

			@media (max-width: $b2){
				position: absolute;
				top: 26px;
				left: -3px;
				padding: 4px 17px;
				@include font-size(10);
				margin-left: 0;
			}
		}
	}
}

.search-button{
	width: 29.32px;
	background-image: url(../images/search.svg);
	background-repeat: no-repeat;
	background-position: 50% 15px;
	background-size: 15.32px 15.32px;
	border-radius: 14px 14px 0 0;
	transition: $time;
	cursor: pointer;

	@media (max-width: $b2){
		position: absolute;
		top: 39px;
		right: calc(30px + 6%);
		width: 25px;
		height: 25px;
		background-position: 50% 50%;
		background-size: 18px;
		border-radius: 6px;
	}

	&:hover,
	&:focus{
		background-color: $red-dark;
		background-image: url(../images/search-white.svg);
	}
}

.fake-select{
	background-color: $red;
	border-radius: 16.5px;
	@include inline;
	margin-left: 33px;
	text-align: left;
	user-select: none;

	@media (max-width: $b2){
		position: absolute;
		top: 26px;
		left: -3px;
		@include font-size(10);
		margin-left: 0;
		border-radius: 10px;
	}

	&.open{
		background-color: $red-dark;
		border-radius: 16.5px 16.5px 0 0;
		position: relative;

		@media (max-width: $b2){
			border-radius: 10px 10px 0 0;
			position: absolute;
		}

		.fake-options{
			display: block;
		}
	}

	a,
	.default{
		color: white;
		padding: 7px 21px;
		width: 162px;

		@media (max-width: $b2){
			padding: 4px 7px;
			width: 97px;
		}
	}

	a{
		padding: 3px 21px;
		display: block;

		@media (max-width: $b2){
			padding: 4px 7px;
		}

		&:hover,
		&:focus{
			color: white;
		}
	}

	.default{
		background-image: url(../images/select-icon-white.svg);
		background-repeat: no-repeat;
		background-position: 100% 50%;
		padding-right: 35px;
		font-weight: 900;

		@media (max-width: $b2){
			padding-right: 20px;
			background-position: 120% 50%;
		}
	}

	.fake-options{
		display: none;
		border-radius: 0 0 16.5px 16.5px;
		background-color: $red-dark;
		position: absolute;
		left: 0;
		top: 100%;
		width: 100%;
		flex-direction: column;
		padding: 6px 0 13px;
		z-index: 1;

		@media (max-width: $b2){
			border-radius: 0 0 10px 10px;
		}
	}
}

#searchform{
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: $main-bg-colour;
	padding: 18px 0;
	display: none;
	margin: 0;

	&.open{
		display: block;
	}

	@media (max-width: $b2){
		padding: 21px 0 6px;
		max-width: 88%;
		left: 50%;
		transform: translateX(-50%);
		z-index: 9;
	}

	::-webkit-input-placeholder {
		@include font-size(21);
		color: $colour;
	}

	:-moz-placeholder { /* Firefox 18- */
		@include font-size(21);
		color: $colour;
	}

	::-moz-placeholder {  /* Firefox 19+ */
		@include font-size(21);
		color: $colour;
	}

	:-ms-input-placeholder {
		@include font-size(21);
		color: $colour;
	}

	.close{
		position: absolute;
		top: 30px;
		right: 0;
		width: 14.1px;
		height: 13.981px;
		background-image: url(../images/cross.svg);
		background-repeat: no-repeat;
		background-size: 14.1px 13.981px;
		transition: $time;
		cursor: pointer;

		&:hover,
		&:focus-within{
			background-image: url(../images/cross-red.svg);
		}
	}

	input{
		margin: 0;

		&[type="text"]{
			@include font-size(21);
			background-color: transparent;
			border: none;
			padding: 2px 4px;
			width: 253px;
			border-radius: 0;
			width: 100%;
			color: $colour;
			border-left: 1px solid $colour;

			&:focus{
				box-shadow: none!important;
			}

			@media (max-width: $b2){
				@include inline;
				vertical-align: middle;
			}
		}
	}

	*[type="submit"]{
		@include font-size(15);
		color: white!important;
		padding: 2px;
		margin: 0;
		position: absolute;
		top: 1px;
		right: 1px;
		width: 30px;
		height: calc(100% - 2px);
		border: none;
		background-color: transparent;
		content: '\f002';
		min-width: 0;

		&:hover,
		&:focus{
			color: $green!important;
		}
	}
}

.mob-only.mob-only{

	@media (min-width: $b2 + 1 ){
		display: none;
	}
}

@import 'burger';

#mainMenu{
	display: flex;
	justify-content: flex-end;
	flex-wrap: wrap;
	width: calc(100% - 188.045px);

	@media (max-width: $b2){
		width: calc(100% - 111.2px);
	}
}

.main-menu > .current-menu-ancestor:not(.menu-item-type-custom),
.main-menu > .current-page-ancestor:not(.menu-item-type-custom),
.main-menu > .current-menu-parent:not(.menu-item-type-custom),
.main-menu > .current_page_parent:not(.menu-item-type-custom),
.main-menu > .current-menu-item:not(.menu-item-type-custom){

	> a{
		color: $red;
		position: relative;

		@media (max-width: $b2){
			color: $colour;
		}

		&:after{
			content: '';
			bottom: 12px;
			position: absolute;
			left: 7.5px;
			right: 7.5px;
			height: 4px;
			background-color: $red;
			transition: $time;
			//width: calc(100% - (7.5px * 2));

			@media (max-width: $b2){
				display: none;
			}
		}
	}
}

.main-menu{
	margin: 0;
	padding: 0;
	@include font-size(0);
	text-align: center;
	display: flex;
	justify-content: space-between;
	list-style-type: none;
	user-select: none;

	@media (max-width: $b2){
		flex-direction: column;
		justify-content: flex-start;
		display: block;
		height: auto;
		background-color: $red-dark;
		position: absolute;
		top: 0;
		right: -295px;
		width: 295px;
		transition: $time;
		height: 100vh;
		overflow-y: scroll;
		z-index: 1;
		padding: 73px 0 10px;
		text-align: left;
		box-shadow: 295px 0 0 295px $red-dark;
		transform: translateX(0) translateZ(0);

		.menu-open &{
			transform: translateX(-100%) translateZ(0);
			transform: translateX(calc(-100% - (100vw/100 * 6))) translateZ(0);
		}
	}

	> li {
		letter-spacing: -1px;

		body.page-id-108 &#menu-item-763 > a,
		body.page-id-228 &#menu-item-763 > a,
		body.page-id-231 &#menu-item-763 > a,
		body.page-id-233 &#menu-item-763 > a,
		body.page-id-235 &#menu-item-763 > a,
		body.page-id-527 &#menu-item-764 > a,
		body.page-id-534 &#menu-item-764 > a,
		body.page-id-550 &#menu-item-765 > a,
		body.page-id-552 &#menu-item-765 > a {
			color: #E83A4F;

			&::after {
					content: '';
					bottom: 12px;
					position: absolute;
					left: 7.5px;
					right: 7.5px;
					height: 4px;
					background-color: #E83A4F;
					-webkit-transition: 0.2s;
					-o-transition: 0.2s;
					transition: 0.2s;
			}
		}

		& > .sub-menu > li > .sub-menu > li > a::after {
			display: none !important;
		}



		&.menu-item-has-children{

			@media (min-width: $b2 + 1){

				&:hover,
				&:focus{

					> a{
						background-color: $red-dark;
						color: white!important;

						&:after{
							background-color: transparent!important;
							transition: 0s;
						}
					}
				}

				> a{

					&:hover,
					&:focus{
						color: white!important;

						&:after{
							background-color: transparent!important;
						}
					}
				}
			}

			.current-menu-ancestor,
			.current-page-ancestor,
			.current-menu-parent,
			.current_page_parent,
			.current-menu-item{

				> a{
					position: relative;
				}
			}

			@media (min-width: $b2 + 1){
				position: relative;

				&:hover,
				&:focus{

					> .sub-menu{
						display: flex;
					}
				}
			}

			@media (max-width: $b2){
				padding-right: 46px;
				background-image: url(../images/accordion-down.svg);
				background-repeat: no-repeat;
				background-position: right 22px top 15px;

				&.open{
					background-image: url(../images/accordion-up.svg);

					> ul.sub-menu{
						display: block;
					}
				}
			}

			> a{
				@include inline;
				vertical-align: middle;
				position: relative;

				@media (max-width: $b2){
					display: block;
				}
			}

			> .sub-menu{
				display: none;
				margin-bottom: 0;
				text-align: left;
				max-width: none;

				@media (min-width: $b2 + 1){
					position: absolute;
					left: 0;
					top: 100%;
					background-color: $red-dark;
					padding: 60px 84px 20px;
					transform: translateX(-50%);
					display: none;
					min-height: 440px;

					> li{
						min-width: 250px;

						&:hover,
						&:focus-within{

							> a{
								text-decoration: none!important;
								cursor: default;

								&:after{
									display: none!important;
								}
							}
						}

						> a{
							@include font-size(16);
							font-weight: 900;
							text-transform: uppercase;
							margin-bottom: 22px;
						}
					}
				}

				@media (max-width: $b2){
					margin-bottom: 15px;

					ul{
						margin-bottom: 15px;
					}
				}

				li{
					display: block;
					padding: 0 0;

					@media (max-width: $b1){

						@media (min-width: $b2 + 1){
							padding: 0 15px;
						}
					}

					@media (max-width: $b2){
						padding-left: 15px!important;
					}
				}

				a{
					padding-top: 9.5px;
					padding-bottom: 9.5px;
					color: white;
					font-weight: 500;
					padding: 3px 0;
					border-radius: 0;

					&:hover,
					&:focus-within{
						color: #fff;
						text-decoration: underline!important;

//						&::after {
//							content: "";
//							display: block;
//							background-color: #fff;
//							height: 4px;
//							position: absolute;
//							top: 100%;
//							right: 0;
//							left: 0;
//						}
					}
				}
			}
		}
	}

	li{
		@include font-size(16);
		line-height: 1em;
		margin-bottom: 0;
		position: relative;

		@media (max-width: $b2){
			padding: 0;
			display: block;
			@include font-size(14);
			padding-left: 33px!important;
		}

		> a{
			@include inline;
			width: auto;
			vertical-align: middle;
			padding: 0;
			color:$colour;
			@include font-size(16);
			text-decoration: none!important;
			line-height: 1.2em;
			text-decoration: none;
			font-weight: 700;
			position: relative;
			padding: 13px 11.5px 17px;
			font-family: $main-font;
			border-radius: 14px 14px 0 0;

			@media (min-width: $b2 +1){

				&:hover,
				&:focus{
					//background-color: $red-dark;
					color: $red;

					&:after{
						content: '';
						bottom: 12px;
						position: absolute;
						left: 7.5px;
						right: 7.5px;
						height: 4px;
						background-color: $red;
						transition: $time;
						//width: calc(100% - (7.5px * 2));

						@media (max-width: $b2){
							display: none;
						}
					}
				}
			}

			@media (max-width: $b2){
				display: block;
				@include font-size(14);
				color: white;
				padding: 12px 0;

				&:hover,
				&:focus{
					color: $grey-dark;
				}
			}
		}
	}
}

$base-calc: $base-width + 150;

//!!COLS

$col-margin: ($gap-width/$base-width)*100;

//
//!!FULL START
//
$no-gap-col:	(100/12);

.col1.no-gap,
.col2.no-gap,
.col3.no-gap,
.col4.no-gap,
.col5.no-gap,
.col6.no-gap,
.col7.no-gap,
.col8.no-gap,
.col9.no-gap,
.col10.no-gap,
.col11.no-gap,
.col12.no-gap{
	margin: 0;

	&.matchHeight{
		margin-bottom: 0;
	}
}

.col1.no-gap{
	width: $no-gap-col + %;
}

.col2.no-gap{
	width: ($no-gap-col * 2) + %;
}

.col3.no-gap{
	width: ($no-gap-col * 3) + %;

	@media (max-width: $base-calc - ($base-calc/4)){
		width: ($no-gap-col * 4) + %;
	}

	@media (max-width: $base-calc - ($base-calc/3)){
		width: ($no-gap-col * 6) + %;
	}

	@media (max-width: $base-calc - ($base-calc/2)){
		width: 100%;
	}
}

.col4.no-gap{
	width: ($no-gap-col * 4) + %;

	@media (max-width: $base-calc - ($base-calc/3)){
		width: ($no-gap-col * 6) + %;
	}

	@media (max-width: $base-calc - ($base-calc/2)){
		width: 100%;
	}
}

.col5.no-gap{
	width: ($no-gap-col * 5) + %;
}

.col6.no-gap{
	width: ($no-gap-col * 6) + %;

	@media (max-width: $base-calc/2 ){
		width: 100%;
	}
}

.col7.no-gap{
	width: ($no-gap-col * 7) + %;
}

.col8.no-gap{
	width: ($no-gap-col * 8) + %;

	@media (max-width: $base-calc - ($base-calc/3)){
		width: ($no-gap-col * 6) + %;
	}

	@media (max-width: $base-calc - ($base-calc/2)){
		width: 100%;
	}
}

.col9.no-gap{
	width: ($no-gap-col * 9) + %;
}

.col10.no-gap{
	width: ($no-gap-col * 10) + %;
}

.col11.no-gap{
	width: ($no-gap-col * 11) + %;
}

.col12.no-gap{
	width: 100%;
}



//
//!!FULL END
//

//
//!!COL START
//
$col-margin	: ($gap-width/$base-width)*100; //column gap calc

$col1		:((100 - ($col-margin * 11))/12);
$col2		:($col1 * 2 + ($col-margin * 1));
$col3		:($col1 * 3 + ($col-margin * 2));
$col4		:($col1 * 4 + ($col-margin * 3));
$col5		:($col1 * 5 + ($col-margin * 4));
$col6		:($col1 * 6 + ($col-margin * 5));
$col7		:($col1 * 7 + ($col-margin * 6));
$col8		:($col1 * 8 + ($col-margin * 7));
$col9		:($col1 * 9 + ($col-margin * 8));
$col10		:($col1 * 10 + ($col-margin * 9));
$col11		:($col1 * 11 + ($col-margin * 10));

@mixin col1{
	width: $col1 + %;
}
@mixin col2{
	width: $col2 + %;
}
@mixin col3{
	width: $col3 + %;
}
@mixin col4{
	width: $col4 + %;
}
@mixin col5{
	width: $col5 + %;
}
@mixin col6{
	width: $col6 + %;
}
@mixin col7{
	width: $col7 + %;
}
@mixin col8{
	width: $col8 + %;
}
@mixin col9{
	width: $col9 + %;
}
@mixin col10{
	width: $col10 + %;
}
@mixin col11{
	width: $col11 + %;
}

.col1,
.col2,
.col3,
.col4,
.col5,
.col6,
.col7,
.col8,
.col9,
.col10,
.col11,
.col12{
	margin-bottom: .2vw;
	margin-left: $col-margin + %;

	&.matchHeight{
		margin-bottom: .4vw;
		margin-left: $col-margin + %;
	}
}

.col1{
	@include col1;
}
.col2{
	@include col2;
}
.col3{
	@include col3;

	@media (min-width: $base-calc - ($base-calc/4)){

		&:nth-of-type(4n + 1){
			margin-left: 0;
		}
	}

	@media (max-width: $base-calc - ($base-calc/4)){
		@include col4;

		@media (min-width: $base-calc - ($base-calc/3)){
			&:nth-of-type(3n + 1){
				margin-left: 0;
			}
		}
	}

	@media (max-width: $base-calc - ($base-calc/3)){
		@include col6;

		@media (min-width: $base-calc - ($base-calc/2)){
			&:nth-of-type(2n + 1){
				margin-left: 0;
			}
		}
	}

	@media (max-width: $base-calc - ($base-calc/2)){
		width: 100%;
		margin-left: 0;
	}
}

.col4{
	@include col4;

	@media (min-width: $base-calc - ($base-calc/3)){

		&:nth-of-type(3n + 1){
			margin-left: 0;
		}
	}

	@media (max-width: $base-calc - ($base-calc/3)){
		@include col6;

		@media (min-width: $base-calc - ($base-calc/2)){
			&:nth-of-type(2n + 1){
				margin-left: 0;
			}
		}
	}

	@media (max-width: $base-calc - ($base-calc/2)){
		width: 100%;
		margin-left: 0;
	}
}

.col5{
	@include col5;
}

.col6{
	@include col6;

	@media (min-width: $base-calc - ($base-calc/2)){

		&:nth-of-type(2n + 1){
			margin-left: 0;
		}
	}

	@media (max-width: $base-calc/2){
		width: 100%;
		margin-left: 0;
	}
}
.col7{
	@include col7;
}
.col8{
	@include col8;

	@media (max-width: $base-calc - ($base-calc/3)){
		@include col6;
	}

	@media (max-width: $base-calc - ($base-calc/2)){
		width: 100%;
	}
}

.col9{
	@include col9;
}
.col10{
	@include col10;
}
.col11{
	@include col11;
}

.col12{
	width: 100%;
}

//
//!!COL END
//

//
//!!ALT GAP START
//
$col-margin-alt	: ($gap-width-alt/$base-width)*100; //column gap calc

$col1-alt		:((100 - ($col-margin-alt * 11))/12);
$col2-alt		:($col1-alt * 2 + ($col-margin-alt * 1));
$col3-alt		:($col1-alt * 3 + ($col-margin-alt * 2));
$col4-alt		:($col1-alt * 4 + ($col-margin-alt * 3));
$col5-alt		:($col1-alt * 5 + ($col-margin-alt * 4));
$col6-alt		:($col1-alt * 6 + ($col-margin-alt * 5));
$col8-alt		:($col1-alt * 8 + ($col-margin-alt * 7));
$col9-alt		:($col1-alt * 9 + ($col-margin-alt * 8));
$col10-alt		:($col1-alt * 10 + ($col-margin-alt * 9));
$col11-alt		:($col1-alt * 11 + ($col-margin-alt * 10));

@mixin col1-alt{
	width: $col1-alt + %;
}

@mixin col2-alt{
	width: $col2-alt + %;
}

@mixin col3-alt{
	width: $col3-alt + %;
}

@mixin col4-alt{
	width: $col4-alt + %;
}

@mixin col5-alt{
	width: $col5-alt + %;
}

@mixin col6-alt{
	width: $col6-alt + %;
}

@mixin col7-alt{
	width: $col7-alt + %;
}

@mixin col8-alt{
	width: $col8-alt + %;
}

@mixin col9-alt{
	width: $col9-alt + %;
}

@mixin col10-alt{
	width: $col10-alt + %;
}

@mixin col11-alt{
	width: $col11-alt + %;
}

.col1.alt,
.col2.alt,
.col3.alt,
.col4.alt,
.col5.alt,
.col6.alt,
.col7.alt,
.col8.alt,
.col9.alt,
.col10.alt,
.col11.alt,
.col12.alt{
	margin-bottom: 2.295vw;
	margin-left: $col-margin-alt + %;

	&.matchHeight{
		margin-bottom: .2vw;
		margin-left: $col-margin-alt + %;
	}
}


.col1.alt{
	@include col1-alt;
}

.col2.alt{
	@include col2-alt;
}

.col3.alt{
	@include col3-alt;

	@media (min-width: $base-calc - ($base-calc/4)){

		&:nth-of-type(4n + 1){
			margin-left: 0;
		}
	}

	@media (max-width: $base-calc - ($base-calc/4)){
		@include col4-alt;

		@media (min-width: $base-calc - ($base-calc/3)){
			&:nth-of-type(3n + 1){
				margin-left: 0;
			}
		}
	}

	@media (max-width: $base-calc - ($base-calc/3)){
		@include col6-alt;

		@media (min-width: $base-calc - ($base-calc/2)){
			&:nth-of-type(2n + 1){
				margin-left: 0;
			}
		}
	}

	@media (max-width: $base-calc - ($base-calc/2)){
		width: 100%;
		margin-left: 0;
	}
}

.col4.alt{
	@include col4-alt;

	@media (min-width: $base-calc - ($base-calc/3)){

		&:nth-of-type(3n + 1){
			margin-left: 0;
		}
	}

	@media (max-width: $base-calc - ($base-calc/3)){
		@include col6-alt;

		@media (min-width: $base-calc - ($base-calc/2)){
			&:nth-of-type(2n + 1){
				margin-left: 0;
			}
		}
	}

	@media (max-width: $base-calc - ($base-calc/2)){
		width: 100%;
		margin-left: 0;
	}
}

.col5.alt{
	@include col5-alt;
}

.col6.alt{
	@include col6-alt;

	@media (min-width: $base-calc - ($base-calc/2)){

		&:nth-of-type(2n + 1){
			margin-left: 0;
		}
	}

	@media (max-width: $base-calc/2){
		width: 100%;
		margin-left: 0;
	}
}

.col7.alt{
	@include col4-alt;
}

.col8.alt{
	@include col8-alt;

	@media (max-width: $base-calc - ($base-calc/3)){
		@include col6-alt;
	}

	@media (max-width: $base-calc - ($base-calc/2)){
		width: 100%;
	}
}

.col9.alt{
	@include col9-alt;
}

.col10.alt{
	@include col10-alt;
}

.col11.alt{
	@include col11-alt;
}

//
//!!ALT GAP END
//

//<div id="burger" class="burger clearfix">menu
//	<div class="line1"></div>
//</div>

$outerHeight:25px;
$outerWidth: 25px;

$backgroundColour: transparent;

$lineHeight: 4px;
$lineWidth: 18.45px;
$gap: 3px;
$radius: 4px;

$topLineColour: $grey-dark;
$topLineColourOpen: white;

$middleLineColour: $grey-dark;
$middleLineColourOpen: transparent;

$bottomLineColour: $grey-dark;
$bottomLineColourOpen: white;

.burger{
	height: $outerHeight;
	width: $outerWidth;
	line-height: $outerHeight;
	background-color: $backgroundColour;
	padding: 1px;
	overflow: hidden;
	-webkit-perspective: 900000px;
	perspective: 900000px;
	box-shadow: 0 0 0 0px $red;
	order: 3;
	position: absolute;
	right: 6%;
	top: 40px;
	display: none;
	//display: block;
	z-index: 2;
	transition: $time;

	@media (max-width: $b2){
		display: block;

		.menu-open &{
			transform: translateX(-249px);
		}
	}

	&:hover{
		cursor: pointer;
	}

	.line1{
		width: $lineWidth;
		max-width: 100%;
		height: $lineHeight;
		background-color: $middleLineColour;
		position: absolute;
		top: (($outerHeight / 2) - ($lineHeight / 2));
		left: 50%;
		border-radius: $radius;
		transform: translateX(-50%);
		background-position: center center;

		&:before,
		&:after{
			width: $lineWidth;
			max-width: 100%;
			height: $lineHeight;
			position: absolute;
			content: '';
			left: 0;
			border-radius: $radius;
			transform-origin: center center;
			background-position: center center;
		}

		&:before{
			transform: translateY(-#{$gap + $lineHeight});
			background-color: $topLineColour;
		}

		&:after{
			transform: translateY(#{$gap + $lineHeight});
			background-color: $bottomLineColour;
		}
	}

	&.close{

		.line1{
			background-color: $middleLineColour;
			transition: visibility .1s .2s, background-color .1s .2s;

			&:before{
				transform: translateY(-#{$gap + $lineHeight});
				background-color: $topLineColour;
				visibility: visible;
				animation: before .5s linear;
				transition: visibility .1s .25s, background-color .1s .25s;
			}

			&:after{
				transform: translateY(#{$gap + $lineHeight});
				background-color: $bottomLineColour;
				visibility: visible;
				animation: after .5s linear;
				transition: visibility .1s .25s, background-color .1s .25s;
			}

			@keyframes before {
				0% {transform: rotate(-45deg) translateY(0);}
				33% {transform: rotate(0deg) translateY(0);}
				66% {transform: translateY(0);}
				100% {transform: translateY(-#{$gap + $lineHeight});}
			}

			@keyframes after {
				0% {transform: rotate(45deg) translateY(0);}
				33% {transform: rotate(0deg) translateY(0);}
				66% {transform: translateY(0);}
				100% {transform: translateY(#{$gap + $lineHeight});}
			}
		}
	}

	&.open{

		.line1{
			transform-origin: 50% 50%;
			transition: visibility .1s .25s, background-color .1s .25s;
			background-color: $middleLineColourOpen;

			&:before{
				transform: rotate(45deg) translateY(0);
				background-color: $topLineColourOpen;
				visibility: visible;
				animation: before-open .5s linear;
				transition: visibility .1s .25s, background-color .1s .25s;
			}

			&:after{
				transform: rotate(-45deg) translateY(0);
				background-color: $bottomLineColourOpen;
				visibility: visible;
				animation: after-open .5s linear;
				transition: visibility .1s .25s, background-color .1s .25s;
			}

			@keyframes before-open {
				0% {transform: translateY(-#{$gap + $lineHeight});}
				33% {transform: translateY(0);}
				66% {transform: translateY(0);}
				100% {transform: rotate(45deg) translateY(0);}
			}

			@keyframes after-open {
				0% {transform: translateY(#{$gap + $lineHeight});}
				33% {transform: translateY(0);}
				66% {transform: translateY(0);}
				100% {transform: rotate(-45deg) translateY(0);}
			}
		}
	}
}

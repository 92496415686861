//!!TEXT ALIGN
.align-left
	{
		text-align: left;
	}

.align-center
	{
		text-align: center;
	}

.align-right
	{
		text-align: right;
	}
.web-link
	{
		font-style: italic;
		word-wrap: break-word;
	}

.relative
	{
		position: relative;
	}
//!!CLEARS
.clear-left
	{
		clear: left;
	}

.clear-both
	{
		clear: both;
	}

.clear-right
	{
		clear: right;
	}
//!!FLOATS
.left
	{
		float: left;
	}

.none
	{
		float: none;
	}

.right
	{
		float: right;
	}
//!!RANDOM
.line-bottom
	{
		border-bottom: 1px solid white;
		padding-bottom: 20px;
		margin-bottom: 30px;
	}

.padding-top
	{
		padding-top: 20px;
	}

.margin-top, .first.margin-top
	{
		margin-top: 20px;
	}

.margin-top-small
	{
		margin-top: 10px;
	}

.margin-none
	{
		margin: 0!important;
	}

.padding-none
	{
		padding: 0!important;
	}

.block
	{
		display: block;
	}

.auto.auto{
	width: auto;
	max-width: 100%;
}


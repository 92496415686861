html{
	font-size:62.5%;
	font-family: $main-font;
	font-weight: 300;
	margin-top: 0 !important;
	overflow-x: hidden;
	opacity: 0;

	&.loaded{
		opacity: 1;
	}

	&.menu-open{
		overflow: hidden;
	}

	&#BTT{
		margin-top: 0!important;
	}
}

body{
	color: $colour;
	background-color: $main-bg-colour;
	@include font-size(16);
	position: relative;
	overflow-x: hidden;
	padding-top: 100px;

	@media (max-width: $b2){
		@include font-size(16 / $divider);
		padding-top: 81.844px;

		.menu-open &{
			overflow: hidden;
		}
	}

	&.compensate-for-scrollbar{
		margin-right: inherit;
	}
}

#wpadminbar{
	display: none;
}

h1,
h2,
h3,
h4,
h5,
h6,
p,
address,
blockquote,
pre,
table,
li{
	color: $colour;
	margin-top: 0;
	font-weight: 300;
	margin-bottom: 1.45em;
	@include line-height(16,24)
}

h1,
h2,
h3,
h4,
h5,
h6,
blockquote{
	font-family: $alt-font;
	font-weight: 700;
	margin-bottom: .35em;
	line-height: 1.11em;

	em{
		font-style: normal;
		color: $green;
	}

	strong{
		font-weight: 700;
	}

	&.title{
		color: $grey;
	}
}

h1{
	@include font-size(70);
	@include line-height(70, 60);

	@media (max-width: $b2){
		@include font-size(70 / $divider);
	}

	&.title{
		@include font-size(100);
		@include line-height(100,103);
		color: #D8DADB;
		letter-spacing: -.075em;
		margin-bottom: .64em;

		@media (max-width: $b2){
			@include font-size(70);
			@include line-height(70,60);
			margin-bottom: 0.58em;
		}
	}
}

h2{
	@include font-size(40);
	@include line-height(40, 40);

	@media (max-width: $b2){
		@include font-size(40 / $divider);
	}
}

h3{
	@include font-size(30);
	@include line-height(30, 33);
	letter-spacing: -.02em;
	font-weight: 900;

	@media (max-width: $b2){
		@include font-size(24);
		@include line-height(24,30);
	}
}

h4{
	@include font-size(20);

	@media (max-width: $b2){
		@include font-size(20 / $divider);
	}
}

h5{
	@include font-size(16);

	@media (max-width: $b2){
		@include font-size(16 / $divider);
	}
}

h6{
	@include font-size(14);
	margin-bottom: 2em;

	@media (max-width: $b2){
		@include font-size(12);
	}
}

p{
	@include font-size(16);

	@media (max-width: $b2){
		@include font-size(16 / $divider);
	}

	a{
		@include font-size(16);
		font-size: inherit;
		color: $red;

		@media (max-width: $b2){
			@include font-size(16 / $divider);
		}

		&:hover,
		&:focus{
			text-decoration: underline;
			color: $green;
		}
	}
}

strong{
	font-weight: 900;
}

em{
	font-weight: 400;
	font-style: italic;
}

blockquote{
	@include font-size(16);
	@include line-height(16,24);
	margin: 0 0 1.4em;
	font-weight: 400;
	position: relative;
	@include inline;

	@media (max-width: $b2){
		@include font-size(16 / $divider);
	}

	&:before{
		content: '“';
		color: $red;
		@include font-size(170);
		position: absolute;

		@media (max-width: $b2){
			@include font-size(80);
		}
	}

	&:before{
		margin-right: -0.1em;
		margin-left: -0.45em;
		top: 0.14em;
	}

	p{
		@include font-size(16);
		@include line-height(16,24);
		margin-bottom: 0;

		@media (max-width: $b2){
			@include font-size(16 / $divider);
		}
	}

	p a,
	a{
		@include font-size(16);

		@media (max-width: $b2){
			@include font-size(16 / $divider);
		}

		&:hover,
		&:focus{
			text-decoration: underline;
		}
	}
}

a{
	@include font-size(16);
	color: $red;
	transition: $time;
	cursor: pointer;

	@media (max-width: $b2){
		@include font-size(16 / $divider);
	}

	&:hover,
	&:focus{
		color: $green;
	}
}

ol,
ul,
dl{
	margin: 0;
	padding-left: 0;
	list-style-position: inside;
	@include font-size(0);
	margin-bottom: 30px;

	li,
	dt,
	dd{
		@include font-size(16);
		margin-bottom: 0;

		@media (max-width: $b2){
			@include font-size(16 / $divider);
		}

		> a{
			@include font-size(16);
			color: $red;
			text-decoration: none;

			@media (max-width: $b2){
				@include font-size(16 / $divider);
			}

			&:hover,
			&:focus{
				text-decoration: underline;
				color: $green;
			}
		}
	}

	dd{
		margin-bottom: 20px;
	}
}

ul{
	list-style-position: outside;
	padding-left: 22px;

	&.no-bullet{
		list-style: none;

		li{
			padding-left: 0;

			&:before{
				display: none;
			}
		}
	}

	li{
		padding-left: 17px;
		list-style: none;
		position: relative;

		&:before{
			content: '';
			width: 7px;
			height: 7px;
			border-radius: 7px;
			border: 1px solid $red;
			background-color: $red;
			position: absolute;
			top: .45em;
			left: 0;
		}
	}
}

ul.tabs,
ul.main-menu,
ul.footer-menu,
ul.sub-menu,
ul.gform_fields,
ul.slick-dots,
ul.social-menu,
ul.flex,
ul.slick,
ul.aside-menu,
ul.aside-menu ul{
	list-style: none;
	padding-left: 0;

	li{
		padding-left: 0;

		&:before{
			display: none;
		}
	}
}

ol:not(.pagination){
	list-style-position: outside;
	padding-left: 0px;
	counter-reset: section;

	li{
		padding-left: 17px;
		list-style: none;
		position: relative;

		&:before{
			counter-increment: section;
			content: counter(section)".";
			position: absolute;
			left: 0;
		}
	}
}

address p,
address{
	@include font-size(19);
	font-style: normal;
	margin-bottom: 8px;

	@media (max-width: $b2){
		@include font-size(19 / $divider);
	}

	p{
		margin-bottom: 0;
	}

	a{
		text-decoration: none;
		color: $red;

		&:hover,
		&:focus{
			text-decoration: underline;
			color: $green;
		}
	}
}

table{
	width: 100%;
}

table,
table tr,
table th,
table td{
	border: none;
	border-collapse: collapse;
	padding: 0;
	@include font-size(16);
	line-height: 1.2em;
	vertical-align: top;
	text-align: left;

	@media (max-width: $b2){
		@include font-size(16 / $divider);
	}
}

table{

	thead{

		th{
			@include font-size(19);
			color: white;
			background-color: $red;
			font-weight: 400;
			padding: 12px 15px;
			position: relative;

			@media (max-width: $b2){
				@include font-size(19 / $divider);
			}

			&:last-of-type{

				&:after{
					display: none;
				}
			}

			&:after{
				content: '';
				width: 1px;
				height: calc(100% - 14px);
				position: absolute;
				top: 7px;
				right: 0;
				background-color: white;
			}
		}
	}

	tbody{

		tr{
			border-bottom: 1px solid $red;

			td{
				padding: 5px 15px;
				position: relative;

				&:last-of-type{

					&:after{
						//display: none;
					}
				}

				&:first-of-type:before,
				&:after{
					content: '';
					width: 1px;
					height: calc(100% - 14px);
					position: absolute;
					top: 7px;
					right: 0;
					background-color: $red;
				}

				&:first-of-type:before{
					right: auto;
					left: 1px;
				}
			}
		}
	}
}

svg,
p.p-img a,
.wp-caption,
video,
img{
	max-width: 100%;
	width: auto;
	height: auto;
	display: block;

	&.alignright {
		float:right;
		margin:0 0 3.4em 2.75em;
	}

	&.alignleft {
		float:left;
		margin:0 2.75em 3.4em 0;
	}

	&.aligncenter {
		clear: both;
		display: block;
		margin: 2.75em auto 3.4em;
	}
}

a img.alignright {
	float:right;
	margin:0 0 3.4em 2.75em;
}

a img.alignleft {
	float:left;
	margin:0 2.75em 3.4em 0;
}

a img.aligncenter {
	clear: both;
	display: block;
	margin: 2.75em auto 3.4em;
}

::selection {
	background: $green;
	color: white;
}

::-moz-selection {
	background: $green;
	color: white;
}

.fieldset{
	margin-bottom: 0;

	.field{
		position: relative;
		padding-bottom: 1px;
	}

	div.mage-error[generated]{
		position: absolute;
		bottom: 5px;
	}
}

.wpcf7-not-valid.wpcf7-not-valid.wpcf7-not-valid,
li.gfield.error input,
.gfield_error input,
.gfield_error select,
.gfield_error .dk-select,
.gfield_error textarea,
input.mage-error,
select.mage-error,
textarea.mage-error{
	box-shadow: inset 0 0 0 2px $red!important;
	border-color: $red;

	.news-letter-con &{
		position: relative;
	}

	&:focus + div.mage-error[generated]{
		@include svg_img("../images/error-arrow-focus");
	}
}

.gfield_error .gfield_checkbox,
.gfield_error .gfield_radio{

	li{
		border-bottom: 3px solid $red;

		footer &{
			border-bottom: 3px solid #941e2c;
		}
	}
}

.wpcf7-not-valid.wpcf7-not-valid.wpcf7-not-valid:focus,
input:focus,
textarea:focus,
select:focus{
	box-shadow: inset 0 0 0 2px $green!important;
	border-color: $green;
}

div.mage-error[generated] {
	color: $red;
	position: absolute;
	margin-top: 4px;
	line-height: 1.1em;
	padding-top: 2px;
	padding-left: 14px;
	@include svg_img("../images/error-arrow");
	background-position: 0 0;
	margin-left: -2px;
	text-align: left;
}

.login-container .fieldset:after{
	color: $red;
}

.browsehappy{
	position: fixed;
	top: 0;
	left: 50%;
	z-index: 999999999;
	background-color: white;
	margin-bottom: 0;
	width: 540px;
	margin-left: -270px;
	text-align: center;
	padding: 4px 2px;
	font-size: 13px;
	color: black;
	border-radius: 0 0 4px 4px;
	line-height: 13px;
	font-weight: 400;

	span{
		background-image: url(../images/browserhappy-cross.png);
		background-repeat: no-repeat;
		height: 13px;
		width: 13px;
		@include inline;
		vertical-align: middle;
		background-position: 50% 50%;

		&:hover{
			background-color: #f96059;
			transition: $time;
		}
	}

	strong{
		color: #f96059;
		font-weight: 400;
	}

	a{
		color: black;
		text-decoration: underline;
		font-weight: 400;

		&:hover{
			color: #f96059;
		}
	}
}

.ta{
	display: table;
	width: 100%;
	height: 1px;
}

.tc{
	display: table-cell;
	vertical-align: middle;
}

.waypoint{
	transition: .5s;
}

.flex{
	display: flex;
	flex-wrap: wrap;

	&.center{
		align-items: center;
		justify-content: center;
	}

	&.top{
		align-self: flex-start;
	}

	&.bottom{
		align-self: flex-end;
	}

	&.no-wrap{
		flex-wrap: nowrap;
	}
}

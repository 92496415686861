//!!FORM
::-webkit-input-placeholder {
	@include font-size(16);
}

:-moz-placeholder { /* Firefox 18- */
	@include font-size(16);
}

::-moz-placeholder {  /* Firefox 19+ */
	@include font-size(16);
}

:-ms-input-placeholder {
	@include font-size(16);
}

form{
	margin: 0 auto;
	width: auto;
	display: block;
	margin-bottom: 24px;

	&:after{
		visibility:hidden;
		display:block;
		font-size:0;
		content:" ";
		clear:both;
		height:0;
	}

	* html &{
		height:1%;
	}
}

$input-bottom-margin: 20px;

input,
input.input-text,
textarea,
select{
	display: block;
	padding: 11px 22px;
	@include font-size(16);
	font-weight: 400;
	font-family: $alt-font;
	width: 100%;
	max-width: 100%;
	margin-bottom: $input-bottom-margin;
	border: 1px solid $grey;
	background-color: white;
	border-radius: 22px;
	color: #717171;
	line-height: 1.3em;
	-webkit-appearance: none;

	@media (max-width: $b2){
		padding: 12px 10px;
		@include font-size(14);
	}
}

textarea{
	height: 124px;
}

input:focus,
input.input-text:focus,
textarea:focus,
select:focus{
	outline: none;
	//background-color: rgba(255,255,255,0.2);
	transition: color 0.5s ease, background-color 0.5s ease;
}

input[type="number"]{
	appearance:textfield;

	&:hover,
	&:focus{
		appearance:textfield;
	}
}

input[type='number'] {
	-moz-appearance:textfield;
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
	-webkit-appearance: none;
	margin: 0;
}

//
//!!SELECT START
//
select{
	padding-right: 46px;
	appearance: none;
	@include svg_img("../images/select-icon");
	background-position: 100% 50%;
	line-height: 44px;
	height: 44px;
	overflow: hidden;
	padding-top: 0;
	padding-bottom: 0;

	@media (max-width: $b2){
		line-height: 44px;
		height: 46px;
	}

	&::-ms-expand {
		display: none;
	}

	.ie9 &,
	.lt-ie9 &{
		background-image: none!important;
		padding: 11px 13px 12px 13px!important;
	}
	option{
		padding-top: 5px;
		padding-bottom: 5px;
	}
}
//
//!!SELECT END
//

label{
	display: block;
	@include font-size(16);
	color: $colour;
	font-weight: 900;
	line-height: 22px;
	margin-bottom: 3px;
	font-family: $alt-font;
	padding-left: 12px;

	@media (max-width: $b2){
		@include font-size(16 / $divider);
	}

	.gfield_required{
		display: none;
	}
}

*[type="submit"]{
	@extend .btn;
	width: auto;
	padding: 18px 10px;
	min-width: 192px;
	font-family: $alt-font;
	border-color: white;
}

//
//!!RADIO & CHECKBOX START
//

.input-radio,
.input-checkbox{
	position: relative;
	@include inline;
	margin-bottom: 5px;

	&:before{
		content: '';
		display: block;
		position: absolute;
	}
}

input[type="radio"],
input[type="checkbox"]{
	display: none;

	.lt-ie9 &{
		@include inline;
		background-color: transparent;
		border: none;
		width: auto;
		min-width: 12px;
		margin-top: 6px;
		margin-bottom: 6px;

		& + .wpcf7-list-item-label,
		& + label{
			padding-left: 0px!important;

			&:before{
				display: none;
			}
		}
	}

	& + .wpcf7-list-item-label,
	& + label{
		padding-left: 36px!important;
		position: relative;
		cursor: pointer;
		line-height: 26px;
		@include inline;
		@include font-size(14);
		font-weight: 400;
		color: $colour;

		@media (max-width: $b2){
			@include font-size(14);
		}

		&:before{
			position: absolute;
			@include svg_img("../images/checkbox");
			content: "";
			width: 26px;
			height: 26px;
			left: 0;
			background-position: 0% 50%;
		}
	}

	&:checked + label:before,
	&:checked + .wpcf7-list-item-label:before,
	& + label.checked:before{
		background-position: 100% 50%;
	}
}

input[type="radio"]{

	& + .wpcf7-list-item-label,
	& + label{

		&:before{
			@include svg_img("../images/radio");
		}
	}
}

.gform_wrapper.gform_wrapper{
	margin-bottom: 0;
	width: 100%;

	.gform_heading h3{
		@include font-size(20);
		font-family: $alt-font;
		font-weight: 900;
		letter-spacing: 0;
		margin-top: 0;
		margin-bottom: .85em;
		text-align: left;

		@media (max-width: $b2){
			@include font-size(22);
			margin-bottom: 0.3em;
		}
	}


	.gform_description{
		@include font-size(17.35);
		display: block;
		margin: 0 auto 1.25em;
		line-height: 1.2em;

		@media (max-width: $b2){
			@include font-size(15.4);
			margin: 0 auto 0.95em;
		}
	}

	.validation_error{
		@include font-size(16);
		color: $red;
		padding-bottom: 10px;
		font-family: $main-font;
	}

	form{
		margin-bottom: 0;

		ul{
			padding: 0;
		}

		.columns{
			float: left;
			width: 48.1%;

			.gfield.gfield.gfield.gfield {
				float: none;
				width: 100%;
				clear: none;

				&.small.small.small{
					float: left;
					width: 48.2%;

					& + .small{
						margin-left: 3.6%;
					}
				}
			}

			~ .columns{
				float: right;
				width: 48.2%;
			}
		}

		.gfield_checkbox{
			margin-bottom: 12px;
		}

		.gform_fields{
			margin-bottom: 0;


			.gfield_label{
				//margin-bottom: 13px;

				.info{

					+ .info{
						display: none;
					}
				}
			}

			.ginput_container{
				width: 100%;
			}

			.gfield{
				list-style: none;
				margin-bottom: 0;
				font-size: 0;
				padding-left: 0;
				background-image: none;

				.smaller{
					@include font-size(12.5);
					display: block;
					line-height: 1em;

					@media (max-width: $b4){
						@include font-size(13);
					}
				}

				&.medium.medium.medium{

					&:nth-of-type(2n + 1){
						float: left;
						width: 48.2%;
						clear: left;

						@media (max-width: $b4){
							width: 100%;
							float: none;
						}
					}

					&:nth-of-type(2n + 2){
						float: right;
						width: 48.2%;

						@media (max-width: $b4){
							width: 100%;
							float: none;
						}
					}
				}

				&.clear.clear.clear.clear{
					float: left;
					clear: both;
				}
				&.gfield_html.gfield_html.gfield_html,
				&.large.large.large{
					float: left;
					clear: both;
					width: 100%;
				}

				&.small.small.small{
					width: 32.2%;
					float: left;

					@media (max-width: $b4){
						width: 100%;
						float: none;
					}

					+ .small{
						margin-left: 1.7%;
						clear: none;

						@media (max-width: $b4){
							width: 100%;
							float: none;
							margin-left: 0;
						}
					}
				}

				&.gfield_html.gfield_html{
					width: 100%;

					& + .radio-checkbox.radio-checkbox.radio-checkbox.radio-checkbox.radio-checkbox{
						float: left;
					}
				}

				&.gform_validation_container{
					display: none;
				}

				input{

					& + .instruction.validation_message{
						padding-bottom: 0;
						bottom: 15px;
						background-image: none;
					}
				}

				.gfield_required{
					color: $red;
					color: $colour;
				}

				&.gfield_error{
					position: relative;

					.validation_message{
						@include font-size(12);
						color: $red;
						position: absolute;
						bottom: 7px;
						left: -2px;
						line-height: 1em;
						padding-left: 14px;
						font-family: $main-font;
						display: none;
					}
				}

				&.gform_validation_container{
					overflow: hidden;
					max-height: 0;
				}

				input,
				input.input-text,
				textarea,
				select{
					width: 100%;
				}

				&.radio-checkbox{

					&.medium.medium{

					}

					&.large.large.large.large.large.large{
						float: none;
						clear: both;
						width: 100%;

						.gfield_radio{
							margin-bottom: 46px;
						}

						.gfield_label{
							display: block;
							float: none;
							width: 100%;
							max-width: none;

							& + .ginput_container{
								float: none;
							}

							.gfield_required{
								display: none;
							}
						}
					}

					.gfield_label{
						display: block;
						float: left;
						width: auto;
						max-width: 310px;

						& + .ginput_container{
							float: right;
							width: auto;

							.gfield_radio{
								margin-bottom: 23px;
							}
						}
					}
				}

//				&.has-description:not(.gfield_error).has-description:not(.gfield_error).has-description:not(.gfield_error).has-description:not(.gfield_error){
//
//					.gfield_description{
//						width: 48.2%;
//						@include font-size(16);
//						line-height: 1.37em;
//						position: absolute;
//						right: 0;
//						bottom: 20px;
//
//
//						@media (max-width: $b4){
//							width: 100%;
//							float: none;
//						}
//					}
//				}
			}
		}

		.gform_footer{
			padding-top: 13px;

			@media (max-width: $b3){
				padding-top: 0;
			}
		}

		.gfield_checkbox,
		.gfield_radio{

			li{
				list-style: none;
				@include inline;
				vertical-align: middle;

				&:last-of-type{

					label{
						padding-right: 0;
					}
				}
			}
		}
	}

	.gfield_visibility_hidden{
		height: 0;
		width: 0;
		overflow: hidden;
		opacity: 0;
	}
}

.page-template-template-form-buy-out{

	.next-to-aside{

	}
}


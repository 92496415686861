@font-face {
	font-family: 'Circular Std';
	src: url('../fonts/CircularStd-Book.eot');
	src: url('../fonts/CircularStd-Book.eot?#iefix') format('embedded-opentype'),
		url('../fonts/CircularStd-Book.woff2') format('woff2'),
		url('../fonts/CircularStd-Book.woff') format('woff'),
		url('../fonts/CircularStd-Book.ttf') format('truetype'),
		url('../fonts/CircularStd-Book.svg#CircularStd-Book') format('svg');
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: 'Circular Std';
	src: url('../fonts/CircularStd-BookItalic.eot');
	src: url('../fonts/CircularStd-BookItalic.eot?#iefix') format('embedded-opentype'),
		url('../fonts/CircularStd-BookItalic.woff2') format('woff2'),
		url('../fonts/CircularStd-BookItalic.woff') format('woff'),
		url('../fonts/CircularStd-BookItalic.ttf') format('truetype'),
		url('../fonts/CircularStd-BookItalic.svg#CircularStd-BookItalic') format('svg');
	font-weight: normal;
	font-style: italic;
}

@font-face {
	font-family: 'Circular Std';
	src: url('../fonts/CircularStd-Medium.eot');
	src: url('../fonts/CircularStd-Medium.eot?#iefix') format('embedded-opentype'),
		url('../fonts/CircularStd-Medium.woff2') format('woff2'),
		url('../fonts/CircularStd-Medium.woff') format('woff'),
		url('../fonts/CircularStd-Medium.ttf') format('truetype'),
		url('../fonts/CircularStd-Medium.svg#CircularStd-Medium') format('svg');
	font-weight: 500;
	font-style: normal;
}



@font-face {
	font-family: 'Circular Std';
	src: url('../fonts/CircularStd-Bold.eot');
	src: url('../fonts/CircularStd-Bold.eot?#iefix') format('embedded-opentype'),
		url('../fonts/CircularStd-Bold.woff2') format('woff2'),
		url('../fonts/CircularStd-Bold.woff') format('woff'),
		url('../fonts/CircularStd-Bold.ttf') format('truetype'),
		url('../fonts/CircularStd-Bold.svg#CircularStd-Bold') format('svg');
	font-weight: bold;
	font-style: normal;
}

@font-face {
	font-family: 'Circular Std';
	src: url('../fonts/CircularStd-Black.eot');
	src: url('../fonts/CircularStd-Black.eot?#iefix') format('embedded-opentype'),
		url('../fonts/CircularStd-Black.woff2') format('woff2'),
		url('../fonts/CircularStd-Black.woff') format('woff'),
		url('../fonts/CircularStd-Black.ttf') format('truetype'),
		url('../fonts/CircularStd-Black.svg#CircularStd-Black') format('svg');
	font-weight: 900;
	font-style: normal;
}

//BREAK POINTS
$b1:				1136px;
$b2:				1070px;
$b3:				769px;
$b4:				520px;
$b5:				450px;

//!!COLOURS
$red:				#E83A4F;
$red-dark:			#BF2638;

$green:				#47B49E;

$blue:				#5BC2E7;

$grey-light:		#D8DADB;
$grey:				#A8A8A8;
$grey-dark:			#434343;

$colour: 			$grey-dark;
$main-bg-colour:	#fafafa;

$font-open:			'Circular Std', sans-serif;
//book - 400
//medium - 500
//bold - 700
//black - 900

$main-font:			$font-open;
$alt-font:			$font-open;

//!!COLUMN CALC
$gap-width:			11px;//gap between the columns
$gap-bottom:		11px;//gap below the columns
$gap-width-alt:		38px;//alt between the columns
$gap-bottom-alt:	38px;//alt gap below the columns

//!!PAGE WIDTH
$base-width:		1136px;
$inner-width:		950px;

//!!SITE TRANSITIONS
$time:				.2s;

//!!FONT SIZE DIVIDER
$divider:			1.14285714286;

//---------------------------------------------------------//
@import 'normalize'; /* site wide variables (colours, font sizes) */
@import 'variables'; /* site wide variables (colours, fonts) */
@import 'toolkit'; /* mixin (@include font-size(??); , @include svg_img("../images/??");) and common classes (.clearfix, .img-replacment) */
@import 'general-tags-and-classes';
@import 'main-menu';
@import 'footer';
@import 'cols'; /* cols and rows and all of its media queries */
@import 'general-overights'; /* stuff like float-left, clear-right, align-center */
@import 'buttons';
@import 'forms';
//@import 'slick';
//@import 'slick-theme';
@import 'fancybox';
//@import 'tabs';

//---------------------------------------------------------//

//
//!!SITE WIDE STYLE
//

//
//!!STRUCTURE START
//

.matchHeight{
	margin-bottom: 0;
}

.lazy-loader{
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	background-color: white;
	z-index: 9999;

	&.loaded{
		opacity: 0;
		display: none;
	}
}

.main-wrapper{
	padding-top: 0;
	padding-bottom: 0px;
	width: 100%;
	min-height: calc(100vh - 86px - 597.41px);
	overflow: hidden;

	&.green{

		.hero,
		.resource-list .r-item .text-con,
		.hero .text-side{
			background-color: $green;
		}

		.under-aside a,
		.next-to-aside a{
			color: $green;

			&:hover,
			&:focus{
				color: darken($green, 10%);
			}
		}

		.btn,
		*[type="submit"]{
			border-color: $green!important;
			background-color: $green;

			&:hover,
			&:focus{
				border-color: darken($green, 20%)!important;
				background-color: darken($green, 20%);
			}

			&.white{
				background-color: $main-bg-colour;
				border-color: $green;
				color: $green!important;

				&:hover,
				&:focus-within{
					background-color: $green;
					color: $main-bg-colour!important;
				}
			}

			&.trans{
				background-color: transparent;
				border-color: white;

				&:hover,
				&:focus{
					background-color: rgba(white, .15);
					outline: none;
				}
			}
		}

		.resource-list .r-item a{

			&:hover,
			&:focus-within{

				~ .img-con:after{
					background-color: darken($green, 20%);
				}
			}

		}

		.text-area.with-tick .gform_body input,
		.text-area.with-tick .gform_body input.input-text,
		.text-area.with-tick .gform_body textarea,
		.text-area.with-tick .gform_body select,
		.inner-download-list .id-item a:hover ~ .detail,
		.inner-download-list .id-item a:focus ~ .detail,
		.people-list .p-item .text-con h5,
		.case-study-content h3,
		.text-area h3{
			color: $green;
		}

		ul li:before{
			border-color: $green;
			background-color: $green;
		}

		// sidebar menus
		.aside-menu li.page_item_has_children {
			background-image: url('../images/plus-green.svg');

			&.open {
				background-image: url('../images/minus-green.svg');
			}

			&.current_page_ancestor {
				background-image: url('../images/minus-green.svg');

				&.open {
					background-image: url('../images/plus-green.svg');
				}
			}
		}
	}
}

.page-wrapper{
	margin: 0 auto;
	padding: 0;
	width: 100%;
	position: relative;
	transition: .25s;
	height: auto;
	padding-top: 0;
}

.wrapper-inner,
.wrapper{
	margin: 0 auto;
	width: 96%;
	padding-bottom: 0;
	max-width: $base-width;
	position: relative;
	clear: both;

	@media (max-width: $b3){
		width: 86%
	}

	&.mob-wide{

		@media (max-width: $b3){
			width: 100%;
		}
	}
}

.wrapper-inner{
	max-width: $inner-width;
}

.mob-wrapper{

	@media (max-width: $b3){
		margin-left: auto;
		margin-right: auto;
		width: 81%;
		padding-bottom: 0;
		max-width: $base-width;
		position: relative;
		clear: both;
		float: none;
	}
}

//
//!!STRUCTURE END
//

//
//!!SIMPLE BASE STYLES START
//

hr{
	height: 1px;
	color: $red;
	background-color: $colour;
	font-size: 0;
	border: 0;
	width: 100%;
	//max-width: $base-width;
	margin-right: auto;
	margin-left: auto;
	margin-bottom: 22px;
	margin-top: 28px;
	position: relative;
}

.full-bg{
	background-position: center center;
	background-size: cover;
	background-repeat: no-repeat;
	position: relative;
}

//
//!!SIMPLE BASE STYLES END
//

//
//!! BREADCRUM START
//

//.breadcrumb-con{
//	display: block;
//	font-family: $alt-font;
//	@include font-size(14);
//	margin-bottom: 54px;
//
//	@media (max-width: $b2){
//		margin-bottom: 25px;
//	}
//
//	@media (max-width: $b2){
//		@include font-size(14/1.15);
//	}
//
//	a{
//		@include font-size(14);
//		text-decoration: none;
//		@include inline;
//		vertical-align: middle;
//		font-weight: 300;
//		text-transform: uppercase;
//		color: $colour;
//
//		@media (max-width: $b2){
//			@include font-size(14/1.15);
//		}
//
//		&:hover,
//		&:focus{
//			color: $blue-light;
//		}
//	}
//
//	.breadcrumb_last{
//		@include font-size(14);
//		color: $blue-light;
//		text-decoration: none;
//		@include inline;
//		vertical-align: middle;
//		font-weight: 300;
//		text-transform: uppercase;
//
//		@media (max-width: $b2){
//			@include font-size(14/1.15);
//		}
//	}
//}

//
//!! BREADCRUM END
//

.pagination-simple{
	padding: 1px 0 9px;

	a{
		@extend .btn;
		color: $colour;
		text-decoration: none;
		line-height: 1em;
		max-width: 49%;
		min-width: 190px;

		@media (max-width: $b3){
			min-width: 110px;
		}

		&:hover,
		&:focus{
			color: $red;
		}

		&[rel="next"]{
			float: right;
		}

		&[rel="prev"]{
			float: left;
		}
	}
}

//simple pagination


//complex pagination
//.pagination{
//	text-align: center;
//	margin-bottom: 0;
//	vertical-align: middle;
//
//	li{
//		list-style: none;
//		@include inline;
//		vertical-align: middle;
//		padding: 0 0;
//		color: $blue;
//		@include font-size(24);
//		font-weight: 700;
//		line-height: 1em;
//		margin: 0 12px;
//
//		@media (max-width: $b2){
//			@include font-size(24/1.15);
//			margin: 0 1px;
//		}
//
//		&:first-of-type{
//			margin-left: 0;
//		}
//
//		&:last-of-type{
//			margin-right: 0;
//		}
//
//		&.active{
//			pointer-events: none;
//
//			a{
//				color: white;
//				background-color: $blue;
//			}
//		}
//
//		a{
//			@include font-size(24);
//			display: block;
//			color: $blue;
//			font-weight: 700;
//			line-height: 58px;
//			min-width: 58px;
//			transition: $time;
//
//			@media (max-width: $b2){
//				@include font-size(24/1.15);
//				line-height: 27px;
//				min-width: 27px;
//			}
//
//			&:hover,
//			&:focus{
//				color: white;
//				background-color: $blue;
//				text-decoration: none;
//			}
//		}
//
//		&.next,
//		&.prev{
//
//			a{
//				@include font-size(0);
//				text-decoration: none;
//				width: 58px;
//				height: 58px;
//				line-height: 0;
//				background-size: 38px 18.677px;
//				background-position: 50% 50%;
//				background-repeat: no-repeat;
//				transition: $time, background-size 0s 0s;
//
//				@media (max-width: $b2){
//					height: 27px;
//					width: 27px;
//					background-size: 27px 13.27px;
//				}
//			}
//		}
//
//		&.next{
//			margin-left: 35px;
//
//			@media (max-width: $b2){
//				margin-left: 5px;
//			}
//
//			a{
//				background-image: url(../images/arrow-right.svg);
//
//				&:after{
//					content: '';
//					position: absolute;
//					background-image: url(../images/arrow-right-blue.svg);
//					width: 0;
//					height: 0;
//					overflow: hidden;
//					opacity: 0;
//					visibility: hidden;
//				}
//
//				&:hover,
//				&:focus{
//					background-image: url(../images/arrow-right-blue.svg);
//					background-color: transparent;
//				}
//			}
//		}
//
//		&.prev{
//			margin-right: 35px;
//
//			@media (max-width: $b2){
//				margin-right: 5px;
//			}
//
//			a{
//				background-image: url(../images/arrow-left.svg);
//
//				&:after{
//					content: '';
//					position: absolute;
//					background-image: url(../images/arrow-left-blue.svg);
//					width: 0;
//					height: 0;
//					overflow: hidden;
//					opacity: 0;
//					visibility: hidden;
//				}
//
//				&:hover,
//				&:focus{
//					background-image: url(../images/arrow-left-blue.svg);
//					background-color: transparent;
//				}
//			}
//		}
//	}
//}

//
//!!SOCIAL MENU START
//
$social-size:		23;
$social-addition:	32;

$facebook:		#3b5999;
$twitter:		#55acee;
$pinterest:		#cb2027;
$rss:			#ff6600;
$slideshare:	#f7941e;
$google-plus:	#dd4b39;
$instagram:		#8a3ab9;
$linkedin:		#007bb5;
$reddit:		#ff4500;
$youtube:		#bb0000;
$mail:			#767676;

.social-menu{
	margin-bottom: 23px;
	display: flex;
	align-items: center;
	justify-content: center;

	@media (max-width: $b2){
		margin-bottom: 0px;
		display: block;
		text-align: center;
	}

	.bottom-btn &{
		justify-content: space-between;
	}

	li{
		list-style: none;
		margin: 0 12px 7px;
		@include font-size($social-size);
		vertical-align: middle;

		.bottom-btn &{
			margin: 0;
		}

		@media (max-width: $b2){
			@include inline;
			vertical-align: middle;
		}

		&:first-of-type{
			margin-left: 0px;
		}

		&:last-of-type{
			margin-right: 0px;
		}

		&.text{
			@include font-size($social-size);
		}

		&.icon a{
			@include font-size($social-size);
			line-height: $social-size + $social-addition + px;
			height:  $social-size + $social-addition + px;
			display: block;
			text-align: center;
			color: white;
			text-decoration: none;
			background-color: transparent;

			.bottom-btn &{
				@include font-size(18);
				line-height: 44px;
				height: 44px;
				width: 44px;
				border-radius: 23px;

				@media (max-width: $b4){
					width: 26px;
					margin: 0 2px;
				}
			}

			i{
				@include font-size($social-size);
				line-height: $social-size + $social-addition + px;
				display: block;

				.bottom-btn &{
					@include font-size(18);
					line-height: 44px;
				}
			}
		}

		&.facebook.icon a{

			.bottom-btn &{
				background-color: $facebook!important;

				&:hover,
				&:focus{
					background-color: darken($facebook, 15%)!important;
					color: white!important;
				}
			}

			&:hover,
			&:focus{
				color: $facebook!important;
			}
		}

		&.twitter.icon a{

			.bottom-btn &{
				background-color: $twitter!important;

				&:hover,
				&:focus{
					background-color: darken($twitter, 15%)!important;
					color: white!important;
				}
			}

			&:hover,
			&:focus{
				color: $twitter!important;
			}
		}

		&.pinterest.icon a{

			&:hover,
			&:focus{
				color: $pinterest!important;
			}
		}

		&.rss.icon a{

			&:hover,
			&:focus{
				color: $rss!important;
			}
		}

		&.slideshare.icon a{

			&:hover,
			&:focus{
				color: $slideshare!important;
			}
		}

		&.google-plus.icon a{

			&:hover,
			&:focus{
				color: $google-plus!important;
			}
		}

		&.instagram.icon a{

			&:hover,
			&:focus{
				color: $instagram!important;
			}
		}

		&.linkedin.icon a{

			.bottom-btn &{
				background-color: $linkedin!important;

				&:hover,
				&:focus{
					background-color: darken($linkedin, 15%)!important;
					color: white!important;
				}
			}

			&:hover,
			&:focus{
				color: $linkedin!important;
			}
		}

		&.reddit.icon a{

			&:hover,
			&:focus{
				color: $reddit!important;
			}
		}

		&.youtube.icon a{

			&:hover,
			&:focus{
				color: $youtube!important;
			}
		}

		&.mail.icon a{

			.bottom-btn &{
				background-color: $mail!important;

				&:hover,
				&:focus{
					background-color: darken($mail, 15%)!important;
					color: white!important;
				}
			}

			&:hover,
			&:focus{
				color: $mail!important;
			}
		}
	}
}

//
//!!SOCIAL MENU END
//

//
//!!PAGE SPECIFIC
//

.waypoint{
	opacity: 0;
	visibility: hidden;
	transform: translateY(50px);
	transition: .75s;

	&.animate{
		opacity: 1;
		visibility: visible;
		transform: translateY(0);
	}
}

.hero{
	background-color: $red;

	@media (max-width: $b3){
		flex-direction: column;
	}

	.hero-title{
		@include font-size(170);
		@include line-height(170,103);
		letter-spacing: -.099em;
		padding-top: .66em;
		margin-bottom: 5px;

		@media (max-width: $b2){
			@include font-size(100);
		}

		@media (max-width: $b3){
			padding-top: 0;
		}
	}

	.text-side{
		width: 43.75%;
		background-color: $red;
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		text-align: right;

		@media (max-width: $b3){
			width: 100%;
			order: 2;
			text-align: right;
			padding: 48px 42px 27px !important;

			.home &{
				text-align: left;
			}
		}

		.home &{
			text-align: left;
		}

		.hero-title{
			text-align: right;
			padding-right: .27em;
			align-self: flex-start;
			width: 100%;

			@media (max-width: $b3){
				text-align: left;
				padding-right: 0;
				padding-top: 0;
				margin-bottom: .33em;
			}

			.mob-only-title{
				display: none;

				@media (max-width: $b3){
					@include inline;
				}
			}
		}

		*{
			color: white;
		}

		.bottom-text{
			align-self: flex-end;
			padding: 5px 34px 5px 0;
			max-width: 90%;

			@media (max-width: $b3){
				align-self: auto;
				padding: 0!important;
				max-width: 100%!important;
			}

			h2{
				letter-spacing: -.02em;
			}

			h1{
				letter-spacing: -.05em;
				font-weight: 900;
				@include line-height(70, 70);

				@media (max-width: $b3){
					@include font-size(40);
				}
			}

			.single-post &{
				max-width: 336px;
				padding: 5px 29px 8px 0;
			}

			.page-template-default &{
				max-width: 421px;
				padding: 5px 33px 0px 0px;
			}

			.single-case_studies &{
				max-width: 458px;
				padding: 5px 28px 16px 0px;
			}

			.home &{
				padding: 5px 57px 45px 0;
				max-width: 336px;
			}
		}
	}

	.img-side{
		min-height: 440px;
		width: 56.25%;

		.page-template-template-form-apply-online &{
			min-height: 300px;

			@media (max-width: $b3){
				min-height: 91px;
			}
		}

		@media (max-width: $b3){
			width: 100%;
			min-height: 217px;
			order: 1;
		}

		.hero-title{
			padding-left: .15em;

			@media (max-width: $b3){
				display: none;
			}
		}

		*{
			color: $red;
		}
	}
}

.introduction{
	max-width: $inner-width;
	justify-content: space-between;
	padding: 92px 0 56px;

	@media (max-width: $b3){
		flex-direction: column;
		padding: 37px 0 45px;
	}

	h3{
		color: $red;

		@media (max-width: $b2){
			@include font-size(30);
		}
	}

	.ls{
		width: 33%;

		@media (max-width: $b3){
			width: 100%;
		}
	}

	.rs{
		width: 58.6%;

		@media (max-width: $b3){
			width: 100%;
			padding: 18px 0 0;
		}
	}

	.btn-con{
		padding-top: 21px;

		@media (max-width: $b3){
			padding-top: 10px;

			.btn{
				width: 100%;
			}
		}
	}
}

.offset-con{

	.offset{
		margin-bottom: 10px;

		@media (max-width: $b3){
			flex-direction: column;
		}

		&:nth-of-type(2n + 2){

			.text-side{
				background-color: $green;
				margin-left: 0;

				@media (max-width: $b3){
					order: 2;
				}
			}

			.img-side{
				margin-left: 0.5%;
				order: 2;

				@media (max-width: $b3){
					order: 1;
					margin-left: 0;
				}
			}
		}

		.img-side{
			width: (807 / 1440 * 100%); // 54.75
			min-height: 535px;

			@media (max-width: $b3){
				width: 100%;
				min-height: 226px;
				margin-bottom: 10px;
			}
		}

		.text-side{
			width: (622 / 1440 * 100%); //44.75
			background-color: $red;
			display: flex;
			flex-direction: column;
			justify-content: center;
			align-items: center;
			margin-left: 0.5%;
			position: relative;

			@media (max-width: $b3){
				width: 100%;
				margin-left: 0;
			}

			*{
				color: white;
			}

			.icon{
				position: absolute;
				top: 34px;
				right: 13.5%;

				@media (max-width: $b3){
					right: auto;
					top: 37px;
					left: 11%;
				}
			}

			.inner{
				padding: 150px 0;
				max-width: (360 / 622 * 100%); // 73

				@media (max-width: $b3){
					padding: 128px 0 15px;
					max-width: 77%;
				}

				h3{

					@media (max-width: $b2){
						@include font-size(26);
						margin-bottom: 1.2em;
					}
				}

				.btn-con{
					padding-top: 24px;
					justify-content: space-between;

					.btn{
						min-width: 150px;
					}

					@media (max-width: $b2){
						padding-top: 9px;
						flex-direction: column;

						.btn{
							width: 100%;
							margin-bottom: 28px;
						}
					}
				}
			}
		}
	}
}

.steps-con{
	padding: 75px 0 50px;
	max-width: 762px;

	@media (max-width: $b3){
		padding: 35px 0 16px;
	}

	.steps{

		.step{
			flex-wrap: nowrap;
			position: relative;

			@media (max-width: $b3){
				margin-bottom: 39px;
			}

			a{
				position: absolute;
				top: 0;
				left: 0;
				width: 100%;
				height: 100%;
				z-index: 2;

				&:hover,
				&:focus{

					~ .text-side{

						h3{
							color: $red-dark;
						}
					}
				}
			}

			h3{
				color: $red;
				transition: $time;
			}

			.icon{

				@media (max-width: $b3){
					width: 76px;
					padding-left: 14px;
					padding-top: 3px;
				}
			}

			.text-side{
				flex-shrink: 1;
				padding-left: 48px;

				@media (max-width: $b3){
					padding-left: 28px;
				}
			}
		}
	}
}

.page-list{
	list-style: none;
	margin: 0 auto;
	padding: 100px 0;

	.p-item{
		position: relative;
		text-align: center;

		h3{
			margin: 0;
		}

		a{
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			z-index: 2;


			&:hover,
			&:focus{

				~ .img-con:after{
					background-color: rgba($red, .4);
				}

				~ .text-con{

					h3{
						color: red;
					}
				}
			}
		}

		.img-con{
			height: 200px;

			&:after{
				content: '';
				position: absolute;
				top: 0;
				left: 0;
				width: 100%;
				height: 100%;
				transition: $time;
			}
		}

		.text-con{
			padding: 15px 5px;
		}
	}
}

.aside-next{
	display: flex;
	justify-content: space-between;
	padding: 47px 0 99px;

	@media (max-width: $b3){
		flex-direction: column;
		padding: 45px 0 30px;
	}

	.single-post &{
		padding: 45px 0 34px;

		@media (max-width: $b3){
			padding: 45px 0 30px;
		}
	}

	.page-template-template-people &{
		padding: 47px 0 34px;

		@media (max-width: $b3){
			padding: 45px 0 30px;
		}
	}

	aside{
		width: 294px;

		@media (max-width: 1369px){
			width: 230px;
		}

		@media (max-width: $b3){
			width: 100%;
		}

		.single-post &{
			padding: 3.9em 0 0;
		}

		h3{
			color: $red;
			text-align: right;

			@media (max-width: $b3){
				text-align: left;
			}
		}

		h2{
			letter-spacing: -.075em;
			color: $grey-light;
			line-height: 1.2em;
			margin-bottom: .1em;
			width: calc(100% + 10%);
			max-width: none;
			font-weight: 900;

			@media (max-width: $b3){
				width: 100%;
				padding-right: 40px;
				background-image: url(../images/accordion-down.svg);
				background-repeat: no-repeat;
				background-position: 100% 50%;

				+ .aside-menu{
					display: none;
				}

				&.open{
					background-image: url(../images/accordion-up.svg);

					+ .aside-menu{
						display: block;
					}
				}

				&.block-title{

					&.open{

						~ .link-blocks{
							display: block;
						}
					}

					~ .link-blocks{
						display: none;
					}
				}
			}
		}

		.aside-menu{

			+ h2{
				margin-bottom: .27em;
			}
		}
	}

	.next-to-aside{
		width: calc(100% - 294px - 10%);
		padding: 57px 0 10px;

		@media (max-width: 1369px){
			width: calc(100% - 230px - 20px);
		}

		@media (max-width: $b3){
			width: 100%;
			padding: 0 0 10px;
		}

		.text-area{

			li{
				margin-bottom: 10px;
			}
		}

		.page-template-template-form-apply-online &{
			width: calc(100% - 294px - 8.6%);
			padding: 25px 0 10px;

			@media (max-width: 1369px){
				width: calc(100% - 230px - 20px);
			}

			@media (max-width: $b3){
				width: 100%;
			}
		}

		.page-id-342 &{
			width: calc(100% - 294px - 5.59%);

			@media (max-width: 1369px){
				width: calc(100% - 230px - 20px);
			}

			@media (max-width: $b3){
				width: 100%;
			}
		}

		.page-id-369 &{
			width: calc(100% - 294px - 7.2%);

			@media (max-width: 1369px){
				width: calc(100% - 230px - 20px);
			}

			@media (max-width: $b3){
				width: 100%;
			}
		}

		.single-post &{
			padding: 0 0 9px;
		}

		.single &{

			.download{
				margin-bottom: 75px;
			}

			.media-con{
				margin-bottom: 24px;
			}
		}

		.date,
		.by{
			color: #BEBEBE;
			@include inline;
			font-style: italic;
			margin-bottom: .3em;
		}

		.by{
			margin-bottom: 1.7em
		}

		.accordion{
			padding: 15px 0 0;

			dd{

				&:last-of-type{
					margin-bottom: 0;
				}
			}
		}

		.steps-con{
			padding: 43px 0 0;
			margin: 0 auto;
		}

		.text-area{

			.gform_wrapper.gform_wrapper form .gform_footer{
				padding-top: 29px;
			}

			.gform_wrapper{
				padding: 15px 0 38px;
			}

			*[type="submit"]{
				width: 48.2%;

				@media (max-width: $b4){
					width: 100%;
				}
			}
		}
	}
}

.link-blocks{
	border: 1px solid $grey-light;
	display: flex;
	align-items: center;
	padding: 14.5px 20px;
	margin-bottom: 13px;
	position: relative;

	a{
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		z-index: 2;

		&:hover,
		&:focus{

			~ .text-side{

				p{
					color: $colour;
				}
			}
		}
	}

	p{
		color: $red;
		margin-bottom: 0;
		font-weight: 900;
	}

	img{
		margin-right: 21px;
	}
}

.aside-menu{
	overflow: hidden;
	user-select: none;
	margin-bottom: 41px;

	li{

//		&.current_page_ancestor.page_item_has_children{
//			background-image: url(../images/minus.svg);
//
//			&.open{
//				background-image: url(../images/plus.svg);
//
//				> .children{
//					display: none;
//				}
//			}
//
//			.children{
//				display: block;
//			}
//		}

		&.page_item_has_children{
			cursor: pointer;
			position: relative;
			background-image: url(../images/minus.svg);
			background-repeat: no-repeat;
			background-position: top 15px right 0;
			background-size: 16px 16px;

			&.state{
				background-image: url(../images/plus.svg);

				> .children{
					display: none;
				}
			}

			> a{
				margin-right: 60px;
			}
		}

		&.current_page_item{

			> a{
				font-weight: 900;
				color: $colour;
			}
		}

		a{
			@include font-size(16);
			color: $grey;
			padding: 9.5px 0;
			display: block;
			//border-bottom: 1px solid $grey;
			position: relative;

			&:after{
				content: '';
				position: absolute;
				left: 0;
				bottom: 0;
				width: 200%;
				height: 1px;
				background-color: #E2E2E2;
				max-width: none;
			}

			&:hover,
			&:focus{
				color: $red;
				text-decoration: none;
			}
		}
	}

	& > li {
		&.current-page-parent,
		&.current_page_ancestor {
			& > a {
				color: #434343;
			}
		}

		> a {
			color: #434343;
		}
	}

	.children{
		margin: 0;
		display: block;

		a{
			padding-left: 20px;
		}

		li.current_page_ancestor{

			> a{
				color: #434343;
				font-weight: 900;
			}
		}

		li.page_item_has_children{
			background-image: none;

			.children{
				display: none;
			}

			> a{
				margin-right: 0;
			}
		}
	}
}

.text-area{

	&.default{
		padding: 98px 0 77px;
		max-width: 743px;

		@media (max-width: $b3){
			padding: 45px 0 45px;
		}

		p{

			+ h5{
				margin-top: 3.2em;
			}
		}

		h5{
			margin-bottom: 1.8em;
			letter-spacing: .009em;
		}
	}

	.single-post &{
		padding-bottom: 16px;
	}

	&.with-tick{

		.gform_wrapper{
			padding: 25px 0 20px;
		}

		.gform_wrapper.gform_wrapper form .gfield_checkbox li,
		.gform_wrapper.gform_wrapper form .gfield_radio li{
			padding: 22px 0 0;
		}

		.gform_button.gform_button.gform_button[type="submit"]{
			min-width: 150px;
			border-color: $red;
			width: auto;

			&:hover,
			&:focus{
				border-color: $red-dark;
			}
		}

		#gform_wrapper_7{

			.gfield_label{
				display: none;
			}

			.gfield_checkbox li:last-of-type label,
			.gfield_radio li:last-of-type label{
				font-weight: 900;
				@include font-size(16);
			}
		}

		.gform_body{

			input,
			input.input-text,
			textarea,
			select{
				color: $red;
				border-radius: 0;
				border-color: $grey-light;
				font-weight: 900;
			}

			select{
				line-height: 56px;
				height: 56px;
			}
		}

		ul{
			margin-bottom: 86px;

			+ p{
				@include line-height(16,28);
				margin-bottom: 2.4em;
			}

			li{
				font-weight: 900;
				padding-left: 12px;
				margin-bottom: 1.72em;
				@include line-height(16,28);


				&:before{
					background-image: url(../images/tickbox.svg);
					background-repeat: no-repeat;
					background-color: transparent;
					border: none;
					width: 30.2px;
					height: 26px;
					top: .11em;
					left: -24px;
					border-radius: 0;

					@media (max-width: $b2){
						width: 30.2px/ $divider;
						height: 26px/ $divider;
						background-size: 30.2px/ $divider 26px/ $divider;

					}
				}
			}
		}
	}

	h3{
		color: $red;
		@include line-height(30, 36);
		letter-spacing: .007em;
		margin-bottom: .85em;
	}

	ul,
	.btn-con{

		+ h3{
			margin-top: 2em;
			margin-bottom: 1em;
		}
	}

	.btn-con{
		padding-top: 2px;

		+ h3{
			margin-top: 1.7em;
		}
	}

	.btn{
		@include font-size(14);
		padding: 10.5px 20px;
	}
}

.page-id-527 .aside-next{
	padding-bottom: 62px;
}

.top-person{
	margin-bottom: 3em;

	img{
		margin-right: 5.5%;
		align-self: flex-start;

		@media (max-width: $b3){
			margin-right: 10px;
			max-width: 110px;
		}
	}

	h3{
		color: $red;
		@include line-height(30, 36);
	}
}

.people-list{
	padding: 8px 0 10px;
	justify-content: center;

	.p-item{
		border: 1px solid $grey-light;
		position: relative;
		margin-bottom: 32px;

		a{
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			z-index: 2;

			&:hover,
			&:focus{

				~ .img-con:after{
					background-color: rgba($red, .4);
				}
			}
		}

		.img-con{
			height: 132px;
			flex: 1 1 auto;

			&:after{
				content: '';
				position: absolute;
				top: 0;
				left: 0;
				width: 100%;
				height: 100%;
				transition: $time;
			}
		}

		.text-con{
			padding: 14px 16px 34px;
			flex: 0 0 auto;

			@media (max-width: $b3){
				padding: 14px 16px 14px;
			}

			h5{
				color: $red;
				font-weight: 900;
				letter-spacing: -.02em;
				margin-bottom: .25em;
				transition: $time;
			}

			p{
				margin-bottom: 0;
			}
		}
	}
}

.filter-top{
	margin: 81px auto 45px;

	@media (max-width: $b3){
		margin: 45px auto;
	}

	.next-to-aside &{
		margin: 7px auto 51px;

		@media (max-width: $b3){
			margin: 45px auto;
		}
	}

	label{
		@include font-size(16);
		padding-bottom: 2px;
	}

	select{
		width: auto;
		min-width: 352px;

		@media (max-width: $b3){
			min-width: 0;
			width: 100%;
		}
	}
}

.news-con{
	padding: 0 0 39px;
}

.news-list{
	margin-bottom: 3px;

	.n-item{
		display: flex;
		flex-direction: column;
		border: 1px solid $grey-light;
		@include col4-alt;
		margin-left: $col-margin-alt + %;
		margin-bottom: 32px;

		@media (min-width: $base-calc - ($base-calc/3)){

			&:nth-of-type(3n + 2){
				margin-left: 0;
			}
		}

		@media (max-width: $base-calc - ($base-calc/3)){
			@include col6-alt;

			@media (min-width: $base-calc - ($base-calc/2)){
				&:nth-of-type(2n + 2){
					margin-left: 0;
				}
			}
		}

		@media (max-width: $base-calc - ($base-calc/2)){
			width: 100%;
			margin-left: 0;
		}

		&:nth-of-type(1){
			width: 100%;
			flex-direction: row;
			margin-left: 0;

			@media (max-width: $base-calc - ($base-calc/2)){
				flex-direction: column;
			}

			.img-con{
				min-height: 426px;
				width: 50.75%;

				@media (max-width: $base-calc - ($base-calc/2)){
					width: 100%;
					min-height: 183px;
				}
			}

			.text-side{
				width: 49.25%;
				padding: 67px 36px 35px 65px;

				@media (max-width: $base-calc - ($base-calc/3)){
					padding: 30px 21px 10px 25px;
				}

				@media (max-width: $base-calc - ($base-calc/2)){
					width: 100%;
					padding: 25px 15px 10px;
				}
			}
		}

		h3{
			@include font-size(24);
			@include line-height(24, 26);
			letter-spacing: -.03em;
			color: $red;
			margin-bottom: .15em;
		}

		.date{
			color: #BEBEBE;
			@include inline;
			font-style: italic;
			margin-bottom: .6em;
		}

		.img-con{
			min-height: 183px;
			width: 100%;
			position: relative;

			a{
				position: absolute;
				top: 0;
				left: 0;
				width: 100%;
				height: 100%;
				z-index: 2;

				&:hover,
				&:focus{
					background-color: rgba($red, .4);
				}
			}
		}

		.text-side{
			width: 100%;
			padding: 30px 21px 10px 25px;
			display: flex;
			flex-direction: column;
			background-color: white;
			flex: 1 1 auto;

			@media (max-width: $base-calc - ($base-calc/2)){
				padding: 25px 15px 10px;
			}

			.text-con{
				flex: 1 1 auto;
			}

			.btn-con{
				align-self: flex-end;

				.btn{
					padding: 6px 20px;
				}
			}
		}
	}
}

.ajax-btn-con{

	.btn{
		margin-bottom: 0;
		padding: 6px 32px;

		&:hover,
		&:focus{
			background-color: $red!important;
			color: white!important;
		}
	}

	&.no-more{
		display: none;
	}
}

.filter-top,
.ajax-btn-con{

	&.loading{
		position: relative;

		&:before{
			content: '';
			width: 100%;
			height: 100%;
			position: absolute;
			top: 0;
			left: 0;
			background-color: rgba($main-bg-colour, .9);
			z-index: 1;
		}

		&:after{
			content: '';
			width: 18px;
			height: 18px;
			position: absolute;
			top: 50%;
			left: 50%;
			animation-name: rotate;
			animation-fill-mode: forwards;
			animation-iteration-count: infinite;
			animation-duration: 2.5s;
			animation-timing-function: linear;
			transform-origin: 50% 50%;
			background-image: url(../images/loader.svg);
			background-repeat: no-repeat;
			background-size: 18px 18px;
			z-index: 2;

			@keyframes rotate {
				0%{
					transform: translate(-50%, -50%) rotate(0deg);
				}

				99.999%{
					transform: translate(-50%, -50%) rotate(359.999deg);
				}
			}
		}
	}
}

.bottom-btn{
	display: flex;
	justify-content: space-between;

	.btn.share,
	.btn.white{
		padding: 12.5px 59px;
		position: relative;

		@media (max-width: $b4){
			padding: 12.5px 41px;
		}


		&:hover,
		&:focus{

			svg path{
				fill: $main-bg-colour;
			}
		}

		svg{
			position: absolute;
			top: 50%;
			left: 18px;
			transform:translateY(-50%);

			path{
				fill: $red;
				transition: $time;
			}
		}
	}

	.btn.white{
		align-self: flex-start;

		svg{
			height: 14px;
		}
	}

	.btn.share{
		padding: 12.5px 57px;
		border-color: $blue;
		background-color: $blue;

		@media (max-width: $b4){
			padding: 12.5px 31px;
		}

		&:hover,
		&:focus{
			background-color: white;
			color: $blue!important;

			svg path{
				fill: $blue;
			}
		}

		svg{
			left: 22px;
			top: 13px;
			transform: none;

			@media (max-width: $b4){
				left: 7px;
			}

			path{
				fill: white;
			}
		}
	}

	.share-con{
		position: relative;

		&.open{

			.btn.share{
				transform: translateX(-102%);
				transform: translateX(calc(-100% - 4px));
			}

			.social-menu{
				pointer-events: auto;
				z-index: 0;

				li{
					opacity: 1;
					visibility: visible;

					@for $i from 1 through 4{
						&:nth-of-type(#{$i}){
							transition-delay: .15s * $i;
						}
					}

				}
			}
		}

		.social-menu{
			position: absolute;
			top: 0;
			left: 0;
			right: 0;
			z-index: -1;
			pointer-events: none;

			li{
				opacity: 0;
				visibility: hidden;
				transition: $time;
			}
		}
	}
}

.team-con{
	padding: 6px 0 46px;
	margin: 0 auto;
	max-width: 744px;

	.intro{
		padding: 0 0 16px;

		h1{
			@include font-size(100);
			color: $grey-light;
			letter-spacing: -.075em;
			margin-bottom: 0.28em;

			@media (max-width: $b2){
				@include font-size(70);
				@include line-height(70,60);
				margin-bottom: 0.58em;
			}
		}
	}

	.people-list{

		.p-item{

			.img-con{
				height: 111px;
			}

			.text-con{
				padding: 14px 16px 13px;
			}

			h5{
				margin-bottom: .3em;
			}

			p{
				margin-bottom: .1em;
			}

			.email{
				position: static;
				text-decoration: none;
				color: #B7B7B7;
				@include font-size(14);

				&:hover,
				&:focus{
					color: $red;
				}
			}
		}
	}
}

.s-item{
	width: 100%;
	margin-bottom: 20px;
}

.news-con{
	padding: 0 0 39px;
}

.download-list{
	margin-bottom: 3px;

	.d-item{
		width: 100%;
		border: 1px solid $grey-light;
		display: flex;
		margin-bottom: 25px;

		.img-con{
			width: 156px;
			min-height: 204px;

			@media (max-width: $b3){
				width: 80px;
				min-height: 100px;
			}
		}

		.text-side{
			width: calc(100% - 156px);
			padding: 29px 29px 17px;
			display: flex;
			flex-direction: column;

			@media (max-width: $b3){
				padding: 12px 12px 12px;
				width: calc(100% - 80px);
			}
		}

		h3{
			@include font-size(23);
			@include line-height(23, 25);
			color: $red;
			margin-bottom: .6em;
		}

		.text-con{
			flex: 1 1 auto;

			p{
				margin-bottom: 0;

				@media (max-width: $b3){
					max-height: 3em;
					overflow: hidden;
				}
			}
		}

		.btn-con{
			align-self: flex-end;
			padding-right: 28px;

			@media (max-width: $b3){
				padding-right: 0;
				width: 100%;

				.btn{
					width: 100%;
				}
			}

			.btn{
				padding: 12.5px 37px;
			}
		}
	}
}

.media-list{
	margin-bottom: 3px;

	.m-item{
		display: flex;
		flex-direction: column;
		margin-bottom: 49px;
		width: 100%;

		.text-side{
			padding: 24px 0 17px;
			display: flex;
			flex-direction: column;
		}

		h3{
			@include font-size(23);
			@include line-height(23, 25);
			color: $red;
			margin-bottom: .8em;
		}

		.text-con{
			flex: 1 1 auto;

			p{
				margin-bottom: 0;
			}
		}
	}
}

.compare-outer{
	justify-content: flex-end;
	padding: 76px 0 20px;

	.intro{
		align-self: flex-end;
		width: 74.2%;
	}
}

.accordion{
	padding: 101px 0 88px;

	&.wrapper{
		max-width: 743px
	}

	dt{
		margin: 0;
		font-weight: 900;
		border: 1px solid $grey-light;
		border-bottom: none;
		padding: 17px 132px 17px 23px;
		background-image: url(../images/accordion-down.svg);
		background-repeat: no-repeat;
		background-position: right 25px top 50%;
		transition: $time;
		cursor: pointer;

		@media (max-width: $b2){
			padding: 17px 45px 17px 23px;
		}

		&.open{
			background-image: url(../images/accordion-up.svg);

			+ dd{
				max-height: 2000px;
				overflow: auto;
			}
		}
	}

	dd{
		margin: 0 0 14px;
		border: 1px solid $grey-light;
		border-top: none;
		padding: 0px 132px 0 23px;
		max-height: 0;
		overflow: hidden;
		transition: $time;

		@media (max-width: $b2){
			padding: 0px 45px 0 23px;
		}

		p{
			margin-bottom: 1.5em;

			&:first-of-type{
				padding-top: 2px;
			}
		}
	}
}

.inner-download-list{
	padding: 29px 0 10px;

	.id-item{
		border: 1px solid $grey-light;
		display: flex;
		padding: 22px 20px;
		position: relative;

		a{
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;


			&:hover,
			&:focus{

				~ .detail{
					color: $red;
				}
			}

		}

		img{
			margin-right: 22px;
			align-self: center;
		}

		.detail{
			font-weight: 900;
			display: block;
			align-self: center;
			transition: $time;
		}
	}
}

.general-list-con{
	padding: 134px 0 39px;
	max-width: 744px;

	@media (max-width: $b3){
		padding: 45px 0 45px;
	}

	.intro{
		margin-bottom: 57px;
	}
}

.general-list{

	.g-item{
		border: 1px solid $grey-light;
		padding: 32px 35px 31px;
		margin-bottom: 33px;

		@media (max-width: $b3){
			padding: 25px 15px 25px;
		}

		h3{
			color: $red;
			letter-spacing: 0;
			margin-bottom: .5em;
		}

		p{
			margin-bottom: 1.55em;
		}

		dl{
			margin-bottom: 18px;

			dt{
				@include inline;
				font-weight: 900;
				line-height: .9em;
			}


			dd{
				@include inline;
				margin: 0;
				margin-left: 5px;
				line-height: .9em;
			}
		}

		.btn-con{
			flex-direction: row-reverse;

			.btn{
				padding: 12.5px 38px;
			}
		}
	}
}

.single-tenders,
.single-careers{

	.form-con{
		padding: 44px 0 40px;

		p{
			margin-bottom: 2.2em;
		}

		.gform_wrapper.gform_wrapper form .gform_footer{
			padding-top: 22px;
			width: 48.2%;

			@media (max-width: $b4){
				width: 100%;
			}

			input{
				width: 100%;
				padding: 13px 10px;
			}
		}

		.btn{
			width: 48.2%;
			padding: 13px 10px;
			margin-bottom: 19px;

			@media (max-width: $b4){
				width: 100%;
			}

			&.hide-me{
				display: none;
			}
		}

		input[type="radio"] + .wpcf7-list-item-label, input[type="radio"] + label, input[type="checkbox"] + .wpcf7-list-item-label, input[type="checkbox"] + label{
			font-weight: 900;
			@include font-size(16);

			@media (max-width: $b2){
				@include font-size(16 / $divider);
			}
		}

		.large{
			margin-top: -2px;
		}
	}

	.job-form-con{

		.medium.medium.medium.medium.medium.medium.medium{
			clear: left;
			float: left;

			&:nth-of-type(2){

				input,
				input.input-text,
				textarea,
				select{
					margin-bottom: 13px;
				}
			}
		}

	}
}

.gform_confirmation_message{
	padding: 50px 0 80px;
}

.general-con{
	padding: 132px 0 53px;
	max-width: 744px;

	@media (max-width: $b3){
		padding: 45px 0 45px;
	}

	.text-area{
		padding: 0 0 20px;

		h3{
			margin-bottom: .95em;
		}
	}

	dl.styled{
		margin-bottom: 18px;

		dt{
			@include inline;
			font-weight: 900;
			line-height: 1.5em;
		}


		dd{
			@include inline;
			margin: 0;
			margin-left: 5px;
			line-height: 1.5em;
		}
	}
}

.sorry-msg{
	padding: 100px 0;
	text-align: center;
	width: 100%;
}

.page-id-470{

	.main-wrapper{
		padding-bottom: 48px;
	}

	.hero{
		margin-bottom: 92px;
	}

	.main-wrapper{

		.wrapper{
			max-width: 940px;
		}
	}
}

.case-list{
	margin-bottom: 8px;

	.c-item{
		border: 1px solid $grey-light;
		display: flex;
		flex-direction: column;
		margin-bottom: 33px;



		.img-con{
			height: 258px;

			@media (max-width: $b3){
				height: 200px;
			}

			a{
				position: absolute;
				top: 0;
				left: 0;
				width: 100%;
				height: 100%;
				z-index: 2;

				&:hover,
				&:focus{
					background-color: rgba($red, .4);
				}
			}
		}

		.text-con{
			padding: 28px 31px 1px;
			flex: 1 1 auto;

			@media (max-width: $b3){
				padding: 25px 15px 1px;
			}

			h3{
				@include font-size(24);
				color: $red;
				margin-bottom: .9em;
			}
		}

		.btn-con{
			flex-direction: row-reverse;
			padding: 0 31px 25px;

			@media (max-width: $b3){
				padding: 0 15px 25px;
			}

			.btn{
				align-self: flex-end;
				padding: 8.5px 18px;
			}
		}
	}
}

.case-con{
	max-width: 744px;
	padding: 71px 0 30px;

	.date{
		color: #BEBEBE;
		@include inline;
		font-style: italic;
		margin-bottom: 1.7em;

		span{
			@include inline;

			&:nth-of-type(1){
				margin-right: 30px;
			}

			&:nth-of-type(2){
				margin-left: 30px;
			}
		}
	}

	.video-con{
		padding: 31px 0 57px;
	}

	.bottom-btn{
		padding: 43px 0 25px;
	}
}

.galley{
	justify-content: space-between;
	padding: 12px 0 0;
	margin-bottom: 5px;

	li{
		margin-bottom: 35px;
		position: relative;

		&:nth-of-type(1n + 2){
			max-width: 22%;
		}

		a{
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			z-index: 2;

			&:hover,
			&:focus-within{
				background-color: rgba($red, .4);
			}
		}
	}
}

.case-study-content{
	padding: 84px 0 53px;
	text-align: center;
	background-color: #F5F5F5;
	max-width: 1018px;
	width: 100%;
	margin: 0 auto;
	position: relative;

	@media (max-width: $b3){
		padding: 45px 0 45px;
	}

	&:after,
	&:before{
		content: '';
		background-color: #F5F5F5;
		height: 100%;
		width: 100vw;
		position: absolute;
		top: 0;
	}

	&:before{
		right: 100%;
	}

	&:after{
		left: 100%;
	}

	h3{
		color: $red;
		margin-bottom: 1.75em;
	}

	.case{
		margin-bottom: 2.55em;
	}

	a{
		@include font-size(17);
	}
}

.resource-list{
	padding: 97px 0 91px;

	@media (max-width: $b3){
		padding: 45px 0 35px;
	}

	.r-item{
		display: flex;
		flex-direction: column;
		position: relative;
		margin-bottom: 10px;

		a{
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			z-index: 2;

			&:hover,
			&:focus-within{

				~ .img-con:after{
					background-color: rgba($red, .4);
				}
			}
		}

		.img-con{
			height: 198px;
			position: relative;

			@media (max-width: $b3){
				height: 145px;
			}

			&:after{
				content: '';
				position: absolute;
				top: 0;
				left: 0;
				width: 100%;
				height: 100%;
				transition: $time;
			}
		}

		.text-con{
			flex: 1 1 auto;
			background-color: $red;
			padding: 17px 26px 11px;
			text-align: right;

			@media (max-width: $b3){
				padding: 10px 15px 10px;
			}

			h3{
				color: white;
			}
		}
	}
}

.form-map{
	padding: 81px 0 87px;
	justify-content: space-between;
	max-width: 940px;

	.form-side{
		width: 37.3%;

		@media (max-width: $b2){
			width: 45%;
		}

		@media (max-width: $b3){
			width: 100%;
		}

		.gform_body{

			input,
			input.input-text,
			textarea,
			select{
				margin-bottom: 31px;
			}

			textarea{
				height: 215px;
			}
		}

		.gform_wrapper.gform_wrapper form .gform_footer{
			padding-top: 19px;
			padding-bottom: 39px;
		}

		input[type="submit"]{
			width: 100%;
			padding: 8.5px 10px;
		}

		a{
			max-width: none;
			width: calc(100% + 19%);
			text-decoration: underline;
			overflow-wrap: break-word;
			word-wrap: break-word;
			word-break: break-word;
		}
	}

	.map-side{
		width: 50%;

		@media (max-width: $b3){
			width: 100%;
		}

		h4{
			@include font-size(27);
			padding-top: 9px;
		}

		.btn-con{
			padding: 43px 0 10px;
			justify-content: space-between;
			width: calc(100% + 19%);
			max-width: none;

			@media (max-width: 1180px){
				flex-direction: column;
				width: 100%;
			}

			.btn.trans{
				max-width: 45.5%;
				border-color: $red;
				color: $red!important;

				&:hover,
				&:focus{
					background-color: $red;
					color: white!important;
				}

				@media (max-width: 1180px){
					width: 100%;
					max-width: 100%;
					margin-bottom: 0;
				}

			}
		}

	}

	.acf-map{
		height: 306px;
		width: 100%;
		border: 11px solid #F2F2F2;
		margin: 5px 0 39px;

		*{
			max-width: none;
		}

		label{
			font-size: inherit;
			color: inherit;
			line-height: inherit;
			margin: inherit;
			font-family: inherit;
			display: inline-block;
		}
	}
}

.form-split{
	padding: 106px 0 34px;
	max-width: 1038px;
	width: 96%;

	@media (max-width: $b3){
		padding: 45px 0 45px;
	}

	.gform_wrapper{
		padding: 40px 0 10px;

		~ p{
			max-width: 60%;
			margin-top: -61px;

			@media (max-width: $b3){
				max-width: 100%;
				margin-top: 0;
			}

			a{
				text-decoration: underline;
			}
		}
	}

	.gform_fields{
		display: flex;
		flex-wrap: wrap;
	}

	.gform_body{

		input,
		input.input-text,
		textarea,
		select{
			margin-bottom: 32px;
		}

		textarea{
			height: 215px;
		}
	}

	label.gfield_label{
		flex: 1 1 auto;

		@media (max-width: $b3){
			flex: 0 0 auto;
			@include font-size(10);
			line-height: 1em;
		}
	}

	.gfield.medium.medium.medium.medium.medium.medium{
		display: flex;
		flex-direction: column;
		margin-left: 3.5%;

		@media (max-width: $b3){
			margin-left: 1%;
			justify-content: flex-end;
		}

		&:nth-of-type(2n + 1){
			width: 34%;
			float: none;
			margin-left: 0;

			@media (max-width: $b3){
				width: 36%;
			}
		}

		&:nth-of-type(2n + 2){
			width: 34%;
			float: none;
			margin-left: 3.5%;

			@media (max-width: $b3){
				width: 36%;
			}

			@media (max-width: $b3){
				margin-left: 1%;
			}
		}
	}

	.gfield.small.small.small.small.small.small{
		display: flex;
		flex-direction: column;
		width: 25%;
		float: none;
		margin-left: 3.5%;

		@media (max-width: $b3){
			margin-left: 1%;
			width: 26%;
			justify-content: flex-end;
		}

		+ .medium.medium.medium.medium.medium.medium{
			margin-left: 0;

			+ .medium.medium.medium.medium.medium.medium{
				margin-left: 3.5%;

				@media (max-width: $b3){
					margin-left: 1%;
				}
			}
		}

		~ .gfield:not(.large){

			label.gfield_label{
				display: none;
			}
		}
	}

	#field_6_10,
	#field_6_10 ~ .medium.medium.medium.medium.medium.medium{

		.ginput_container{
			position: relative;

			&:after{
				content: '';
				width: 8px;
				height: 1px;
				background-color: $grey;
				left: calc(100% + 4%);
				top: calc(50% - 15px);
				position: absolute;

				@media (max-width: $b3){
					display: none;
				}
			}
		}
	}

	.gform_footer.gform_footer.gform_footer{
		text-align: right;
		padding-top: 9px;

		input{
			padding: 9.5px 10px;
			width: 34.2%;

			@media (max-width: $b3){
				width: 36%;
			}
		}
	}

	.gfield.large.large.large{
		width: 100%;
	}
}

.top-button{
	padding: 89px 0 66px;
	text-align: center;

	@media (max-width: $b2){
		padding: 45px 0;
	}

	h3{
		color: $red;
		margin-bottom: 2.95em;
	}
}

.step-buttons{
	justify-content: space-between;
	max-width: 833px;
	margin: 0 auto;

	@media (max-width: $b4){
		flex-direction: column;
	}

	.step-button{
		background-color: $red;
		border-radius: 40px;
		text-align: center;
		font-weight: 900;
		padding: 0 10px;
		text-decoration: none;
		@include inline;

		@media (max-width: $b4){
			margin-bottom: 10px;
		}

		&:hover,
		&:focus{
			background-color: $red-dark;

			*{
				text-decoration: none;
			}

			.number{
				color: $red;
			}
		}

		.number{
			color: $red-dark;
			@include font-size(40);
			margin-bottom: -0.1em;
		}

		.text{
			color: white;
			max-width: 67%;
			margin: 1.75em auto 1.65em;
		}
	}
}

.step{
	padding: 27px 0 60px;

	.step-title{
		text-align: center;
		@include font-size(60);
		color: #D8DADB;
		margin-bottom: 0.27em;
		letter-spacing: -0.072em;

		@media (max-width: $b2){
			@include font-size(60 / $divider);
		}
	}

	.filler-block{
		height: 1377px;
		background-color: white;
	}

	.small-intro{
		padding-bottom: 39px;
	}
}



#multi-options{
	max-width: 808px;
	margin: 0 auto;
	padding: 74px 0 10px;

	.gform_body,
	.credit{
		margin: 0 auto;
		max-width: 744px;
	}
}

.step3{

	.gform_wrapper{

		.gfield.medium.medium.medium.medium.medium.medium{
			margin-bottom: 60px;

			+ .medium{
				margin-bottom: 44px;
			}
		}

	}

	#result{
		margin-top: -15px;
		clear: both;

		p{
			line-height: 1.7em;
		}

		p{
			margin-bottom: 1.1em;
		}

		h4{
			@include font-size(26);
			@include line-height(26, 28);
			margin-bottom: 2em;
		}

		.result,
		.credit{
			display: none;

			&.show{
				display: block;
			}
		}

		.result{
			border: 1px solid #e3e3e3;
			border-radius: 30px;
			overflow: hidden;
		}

		.result0,
		.result1{
			padding-bottom: 67px;
			background-color: transparent;
			border: none;
			border-radius: 0;
		}

		.result2,
		.result4,
		.result3,
		.result5{

			.inner{
				padding: 63px 78px 43px;
			}

			.titler{
				background-color: $red;
				display: flex;
				align-items: center;
				justify-content: center;
				padding: 10px 5px;
				min-height: 175px;

				*{
					color: white;
					margin: 0 auto;
				}
			}

			p{
				margin-bottom: 2.5em;
			}

			ul{
				margin-left: 54px;
				margin-bottom: 48px;

				@media (max-width: $b4){
					margin-left: 0;
				}
			}

			li{
				margin-bottom: .17em;
			}

			.btn-con{
				padding-top: 26px;
				padding-bottom: 26px;
				justify-content: center;

				@media (max-width: $b4){
					flex-direction: column;
				}
			}


			.btn{
				width: 215px;
				margin: 0 19px 5px;
				padding: 13px 60px;

				@media (max-width: $b4){
					width: 100%;
					margin: 0 auto 10px;
				}

				&:first-of-type{
					margin-left: 0;
				}

				&:last-of-type{
					margin-right: 0;
				}

				&.trans{
					border-color: $red;
					color: $red!important;
				}
			}
		}

		.result5{

			.inner{
				text-align: center;

				h4{
					max-width: 64%;
					margin: 0 auto 2em;
				}
			}
		}

		.result4{

			.inner{
				text-align: center;

				h4{
					max-width: 74%;
					margin: 0 auto 2em;
				}
			}
		}

		.result2,
		.result4{

			.titler{
				background-color: $green;
			}

			ul li:before{
				border-color: $green;
				background-color: $green;
			}

			.btn:not(.trans){
				background-color: $green;
				border-color: $green;

				&:hover,
				&:focus{
					background-color: darken($green, 20%);
					border-color: darken($green, 20%);
				}
			}

			.btn.trans{
				border-color: $green;
				color: $green!important;

				&:hover,
				&:focus{
					background-color: $green;
					color: $main-bg-colour!important;
				}
			}

			p{

				a{
					color: $green;

					&:hover,
					&:focus{
						color: darken($green, 20%);
					}
				}
			}
		}

		.credit{

			.gfield_radio{
				margin-bottom: 15px;

				li{
					line-height: 1.8em;

					+ li{
						margin-left: 94px;
					}
				}
			}

			p{
				margin-bottom: 2.45em;
			}

			> ul{
				margin-left: 9px;
				margin-bottom: 31px;
				background-color: white;
				border: 1px solid #E3E3E3;
				padding: 23px 39px 29px;
				@include inline;
				min-width: 52.5%;

				@media (max-width: $b4){
					margin-left: 0;
				}

				li{
					margin-bottom: 0.26em;
				}

				li:before{
					border-color: $colour;
					background-color: $colour;
				}
			}
		}
	}
}

.gf_page_steps{
	display: flex;
	justify-content: space-between;
	max-width: 85%;
	margin: 0 auto 67px;

	@media (max-width: $b2){
		max-width: 100%;
	}

	.gf_step_clear{
		display: none;
	}

	.gf_step{
		position: relative;

		.gf_step_number{
			width: 85px;
			height: 85px;
			border-radius: 85px;
			line-height: 85px;
			text-align: center;
			@include font-size(50);
			font-weight: 900;
			color: white;
			background-color: #E3E3E3;
			display: block;
			margin: 0 auto 9px;

			@media (max-width: $b2){
				width: 42px;
				height: 42px;
				line-height: 42px;
				@include font-size(28);
			}
		}

		.gf_step_label{
			text-align: center;
			position: absolute;
			left: 50%;
			transform: translateX(-50%);
			width: 170px;
			max-width: none;
			font-weight: 900;
			color: $red;
			opacity: 0;

			.green &{
				color: $green;
			}

			@media (max-width: $b2){
				@include font-size(12);
				width: 75px;
			}
		}

		&.gf_step_completed{

			.gf_step_number{
				background-color: $main-bg-colour;
				border: 4px solid $red;
				color: $red;
				line-height: 77px;

				@media (max-width: $b2){
					border: 2px solid $red;
					line-height: 38px;
				}

				.green &{
					border: 4px solid $green;
					color: $green;

					@media (max-width: $b2){
						border: 2px solid $green;
					}
				}
			}
		}

		&.gf_step_active{

			.gf_step_number{
				background-color: $red;

				.green &{
					background-color: $green;
				}
			}

			.gf_step_label{
				opacity: 1;
			}
		}
	}
}

.page-template-template-form-apply-online .next-to-aside{

	.gfield:not(.gfield_contains_required):not(.gfield_html){

		.gfield_label{

			&:after{
				content: '(optional)';
				color: #BEBEBE;
				padding-left: 5px;
				font-weight: 500;
			}
		}
	}

	.gform_page{

		&:nth-of-type(4){

			.gfield_label{
				min-height: 44px;
			}
		}
	}

	.gform_page_footer{
		position: relative;
	}

	.gform_ajax_spinner{
		position: absolute;
		top: 100%;
		transform: translateX(-50%);
		left: 50%;
	}

	.gform_wrapper.gform_wrapper.gform_wrapper.gform_wrapper form{

		.gform_body{

			.clear.clear.clear.clear.clear.clear.clear{
				clear: both;
				float: left;

				~ .gfield.medium:nth-child(odd){
					float: left;
					clear: left;
				}

				~ .gfield.medium:nth-child(even){
					float: right;
					clear: none;
				}
			}

			h3{
				@include font-size(28);
				padding-left: 13px;
				letter-spacing: 0;
				margin-bottom: 1.2em;
			}

			hr{
				margin-top: 23px;
				margin-bottom: 37px;
			}

			textarea{
				height: 154px;
				margin-bottom: 31px;
			}

			.gfield_html{
				padding: 1px 0;

				&:nth-child(odd):nth-of-type(1n + 20){

					~ .gfield.medium.medium.medium.medium.medium:nth-child(even){
						float: left;
						clear: left;
					}

					~ .gfield.medium.medium.medium.medium.medium:nth-child(odd){
						float: right;
						clear: none;
					}

					~ .clear.clear.clear.clear.clear.clear.clear{
						clear: both;
						float: left;

						~ .gfield.medium:nth-child(odd){
							float: left;
							clear: left;
						}

						~ .gfield.medium:nth-child(even){
							float: right;
							clear: none;
						}
					}
				}

				+ .gfield.medium{
					float: left;

					~ .gfield.medium:nth-child(odd){
						float: right;
						clear: none;
					}

					~ .gfield.medium:nth-child(even){
						float: left;
						clear: left;
					}
				}
			}
		}
	}
}

#field_11_2{
	float: none!important;
	width: 100%!important;
	clear: both!important;

	.gfield_label{
		padding: 0;
		width: 100%;
		@include font-size(30);
		color: $green;
		text-align: center;
		max-width: 100%;
		margin-bottom: 66px;
	}

	.ginput_container{
		width: 100%;

		.gfield_radio{
			display: flex;
			justify-content: space-between;
			margin-bottom: 25px;

			li{
				width: 48.2%;

				&:nth-of-type(1){
					margin-left: 0;
				}

				@media (max-width: $b4){
					width: 100%;
					float: none;
					margin-bottom: 10px;
				}

				input[type="radio"] + label{
					padding-left: 10px!important;
					display: block;
					text-align: center;
					border-radius: 22px;
					background-color: #E3E3E3;
					padding: 10px 10px;
					color: #A3A3A3;
					font-weight: 900;
					transition: $time;
					@include font-size(16);
				}

				input[type="radio"]:checked + label{
					background-color: $green;
					color: white;
				}

				input[type="radio"] + label:before{
					display: none;
				}
			}
		}
	}
}

#field_12_2{
	float: none!important;
	width: 100%!important;
	clear: both!important;

	.gfield_label{
		padding: 0;
		width: 100%;
		@include font-size(30);
		color: $red;
		text-align: center;
		max-width: 100%;
		margin-bottom: 66px;
	}

	.ginput_container{
		width: 100%;

		.gfield_radio{
			display: flex;
			justify-content: space-between;

			li{
				width: 48.2%;

				&:nth-of-type(1){
					margin-left: 0;
				}

				@media (max-width: $b4){
					width: 100%;
					float: none;
					margin-bottom: 10px;
				}

				input[type="radio"] + label{
					padding-left: 10px!important;
					display: block;
					text-align: center;
					border-radius: 22px;
					background-color: #E3E3E3;
					padding: 10px 10px;
					color: #A3A3A3;
					font-weight: 900;
					transition: $time;
					@include font-size(16);
				}

				input[type="radio"]:checked + label{
					background-color: $red;
					color: white;
				}

				input[type="radio"] + label:before{
					display: none;
				}
			}
		}
	}
}

.gfield{

	&.has-description{
		position: relative;

		.gfield_label{

			&:hover,
			&:focus{

				~ .gfield_description{
					opacity: 1;
					visibility: visible;
				}
			}

			.info{
				width: 16.41px;
				height: 16.41px;
				background-size: 16px 16px;
				@include inline;
				margin-left: 5px;
				background-image: url(../images/info.svg);
				background-repeat: no-repeat;
				background-position: 50% 50%;
				transform: translateY(18%);
				transform: translateY(.2em);
				position: relative;

				@media (max-width: 1250px){
					display: block;
					margin-left: 0;
					top: -4px;
				}

				&:hover {
					cursor: pointer;
					z-index: 10;

					.gfield_description {
						opacity: 1;
						visibility: visible;
					}
				}

				.gfield_description {
					background-color: $blue;
					min-width: 298px;
					padding: 17px 20px;
					position: absolute;
					top: 50%;
					left: 100%;
					margin-left: 16px;
					transform: translateY(-50%);
					opacity: 0;
					visibility: hidden;
					transition: all 0.2s ease-in-out;
					border-radius: 12px;
					color: #fff;
					font-size: 13px;
					font-weight: 400;
					line-height: 18px;
					z-index: 10;

					@media (max-width: 1640px){
						min-width: 150px;
					}

					@media (max-width: 1250px){
						padding: 8px 10px;
					}

					&:before{
						content: '';
						width: 0;
						height: 0;
						border-top: 10px solid transparent;
						border-bottom: 10px solid transparent;
						border-right:10px solid $blue;
						position: absolute;
						top: 50%;
						right: 100%;
						transform: translateY(-50%);

						@media (max-width: 1250px){
							display: none;
						}
					}
				}
			}
		}

//		.gfield_description{
//			position: absolute;
//			left: 0;
//			top: calc(100% -  15px);
//			bottom: auto!important;
//			width: 100%!important;
//			border-radius: 22px;
//			border: 1px solid $blue;
//			background-color: white;
//			line-height: 1.4em!important;
//			font-size: 1.3rem!important;
//			padding: 12px;
//			z-index: 1;
//			opacity: 0;
//			visibility: hidden;
//		}
	}
}

.page-template-template-form-apply-online{

	.aside-next{
		padding: 47px 0 43px;
	}

	.next-to-aside{

		.gform_body{

			.gform_page:first-of-type{

				.gform_page_footer{
					justify-content: center;
				}
			}
		}

		.gform_page_footer{
			padding: 20px 0 0;
			display: flex;
			justify-content: space-between;

			@media (max-width: $b4){
				flex-direction: column;
			}

			input{
				width: 48.2%;
				transition: $time;
				font-weight: 900;

				@media (max-width: $b4){
					width: 100%;
					float: none;
				}

				&.gform_previous_button{
					border-color: $red;
					color: $red!important;
					max-width: 131px;

					@media (max-width: $b4){
						max-width: none;
						margin-bottom: 10px;
					}

					&:hover,
					&:focus{
						background-color: $red;
						color: $main-bg-colour!important;
					}
				}

				&:last-of-type{
					border-color: $red;
					background-color: $red;
					color: white!important;
					margin-left: 10px;

					&:first-of-type{
						margin-left: 0;
					}

					@media (max-width: $b4){
						margin-left: 0;
					}

					&:hover,
					&:focus{
						background-color: $red-dark;
						border-color: $red-dark;
						color: white!important;
						outline: none;
					}
				}
			}
		}
	}
}

.gform_confirmation_wrapper {
	width: 100%;
}

.page-template-template-form-apply-online .green .next-to-aside{

	.gform_page_footer{

		input{

			&.gform_previous_button{
				border-color: $green;
				color: $green!important;

				&:hover,
				&:focus{
					background-color: $green;
					color: $main-bg-colour!important;
				}
			}

			&:last-of-type{
				border-color: $green;
				background-color: $green;
				color: white!important;

				&:hover,
				&:focus{
					background-color: darken($green, 20%);
					border-color: darken($green, 20%);
					color: white!important;
				}
			}
		}
	}
}


//apply rent page 3
#field_12_50,
#field_12_56,
#field_11_50,
#field_11_56{

	~ .gfield.medium.medium.medium.medium.medium:nth-child(odd){
		float: left;
		clear: left;
	}

	~ .gfield.medium.medium.medium.medium.medium:nth-child(even){
		float: right;
		clear: none;
	}
}

#field_12_61,
#field_11_61{

	~ .gfield.medium.medium.medium.medium.medium:nth-child(even){
		float: left;
		clear: left;
	}

	~ .gfield.medium.medium.medium.medium.medium:nth-child(odd){
		float: right;
		clear: none;
	}
}

#field_12_68,
#field_12_74,
#field_11_68,
#field_11_74{

	~ .gfield.medium.medium.medium.medium.medium:nth-child(odd){
		float: left;
		clear: left;
	}

	~ .gfield.medium.medium.medium.medium.medium:nth-child(even){
		float: right;
		clear: none;
	}
}
//apply rent page 3


//apply rent page 4
#field_12_82,
#field_11_82{

	~ .gfield.medium.medium.medium.medium.medium:nth-child(odd){
		float: left;
		clear: left;
	}

	~ .gfield.medium.medium.medium.medium.medium:nth-child(even){
		float: right;
		clear: none;
	}
}

.ginput_container_date{

	.clear-multi{
		display: flex;
		justify-content: space-between;

		.ginput_container.ginput_container{

			&:nth-of-type(1),
			&:nth-of-type(2){
				width: 28%
			}

			&:nth-of-type(3){
				width: 35%;
			}
		}

		label{
			display: none;
		}
	}
}

//apply rent page 4

///apply rent page 6
#field_12_126,
#field_11_126{
	float: left;

}
///apply rent page 6

.page-template-template-form-apply-online{

	.radio-checkbox.radio-checkbox.radio-checkbox.radio-checkbox.radio-checkbox{

		label.gfield_label{
			max-width: none;
			width: 100%;
		}

		div.ginput_container{
			width: 100%;

			ul.gfield_radio{
				margin-bottom: 49px;

				li{
					margin-left: 12px;

				}
			}
		}
	}
}

.gfield_html{

	label{
		margin-bottom: 1.5em;
	}
}

.page-template-template-form-buy-out{

	.aside-next{
		padding: 47px 0 78px;
	}

	.next-to-aside{
		padding: 63px 0 10px;

		.gform_wrapper{
			padding: 26px 0 10px;
		}

		.gform_footer .btn,
		.gform_wrapper.gform_wrapper form .gform_fields .large .ginput_container{
			width: 48.2%;

			@media (max-width: $b4){
				width: 100%;
			}
		}

		.gfield.large.has-description{
			// margin-bottom: 31px;
			margin-bottom: 0;
		}

		.gform_footer.gform_footer.gform_footer{
			// padding-top: 66px;
			padding-top: 20px;

			a{
				padding: 13px 10px;
			}
		}
	}

	.under-aside{
		background-color: white;
		border-top: 1px solid #E3E3E3;
		border-bottom: 1px solid #E3E3E3;
		padding: 20px 0 6px;
		margin-bottom: 45px;

		h2{
			text-align: center;
			@include font-size(40);
			color: #D8DADB;
			letter-spacing: -.07em;
			margin-bottom: 1.65em;
		}
	}
}

.testimonials{
	max-width: 870px;

	.testimonial{
		padding-left: 40px;
		padding-right: 40px;

		@media (max-width: $b2){
			padding-left: 20px;
			padding-right: 20px;
		}

		blockquote{
			margin-bottom: .6em;
		}

		.by{
			color: #BEBEBE;
			font-style: italic;
			text-align: right;
		}
	}
}

#form-result{
	padding: 25px 0 10px!important;
	height: auto!important;
	display: flex;
	justify-content: space-between;
	align-items: center;

	.lined{
		width: 48.2%;
		padding: 11px 10px;
		text-align: center;
		border: 1px solid $red;
		border-radius: 22px;
		color: $red;
		font-weight: 900;
	}

	.equals{
		@include font-size(40);
		font-weight: 900;
		line-height: 1em;
		padding: 0 0px 0 20px;
	}

	.value{
		margin-left: 10px;
		@include font-size(40);
		font-weight: 900;
		line-height: 1em;
		padding: 0 70px 0 10px;
	}
}

.page-template-template-form-cost-me{

	.aside-next{
		padding: 47px 0 78px;
	}

	.next-to-aside{
		padding: 58px 0 10px;

		h3{
			color: $red;
		}

		.gform_wrapper{
			padding: 58px 0 10px;
		}

		.gform_footer .btn,
		.gform_wrapper.gform_wrapper form .gform_fields .large .ginput_container{
			width: 48.2%;

			@media (max-width: $b4){
				width: 100%;
			}
		}

		.gfield.medium.medium{
			margin-bottom: 32px;
		}

		.small.small.small.small.small.small{
			margin-bottom: 32px;
		}

		.gfield.large.has-description{
			margin-bottom: 31px;
		}

		.gform_footer.gform_footer.gform_footer{
			padding-top: 24px;

			button,
			a{
				padding: 13px 10px;
			}
		}
	}
}

#mortgage-cost-results{
	padding: 65px 0 0;

	a{
		width: 48.2%;
		padding: 13px 10px;
	}

	.block.without-co-own{
		border: 1px solid #D2D2D2;
		padding: 27px 37px;
		background-color: white;
		margin-top: 43px;
		margin-bottom: 4.7em;

		.label{
			font-weight: 900;
			margin-bottom: .2em;
			@include inline;
			vertical-align: middle;
			margin-bottom: 0;
		}

		.value{
			@include font-size(30);
			margin-left: 10px;
			margin-bottom: 0;
			font-weight: 900;
			@include inline;
			vertical-align: middle;
		}
	}

	.sum-total{
		display: flex;
		justify-content: space-between;
		//align-items: center;

		@media (max-width: $b3){
			flex-wrap: wrap;
		}

		.block{
			border: 1px solid #D2D2D2;
			padding: 24px 7px 17px 7px;
			background-color: white;

			.value{
				@include font-size(30);
				margin-left: 8px;
				margin-bottom: 0;
				font-weight: 900;
				line-height: 1em;

				@media (max-width: $b2){
					@include font-size(25);
					margin-left: 0;
				}
			}

			.label{
				font-weight: 900;
				margin-bottom: .7em;

				&:hover,
				&:focus{

					~ .gfield_description{
						opacity: 1;
						visibility: visible;
					}
				}

				.info{
					width: 16.41px;
					height: 16.41px;
					background-size: 16.41px 16.41px;
					@include inline;
					margin-left: 5px;
					background-image: url(../images/info.svg);
					background-repeat: no-repeat;
					background-position: 50% 50%;
					transform: translateY(18%);
					transform: translateY(.2em);
					position: absolute;
					top: 4px;
					right: 7px;
				}
			}

			&.component{
				width: 21.5%;
				position: relative;

				@media (max-width: $b3){
					width: 47%;
					margin-bottom: 20px;
				}

				&::after {
					@include font-size(30);
					@include line-height(30,23);
					position: absolute;
					top: 50%;
					transform: translateY(-50%);
					font-weight: 900;

					@media (max-width: $b3){
						@include font-size(15);
					}
				}

				&:nth-of-type(1)::after {
					content: "+";
					left: calc(100% + 12px);

					@media (max-width: $b3){
						left: 104%;
					}
				}

				&:nth-of-type(2)::after {
					content: "=";
					left: calc(100% + 10px);

					@media (max-width: $b3){
						left: -9.6%;
						top: calc(100% + 10px);
					}
				}
			}

			.label{

				&:hover,
				&:focus{

					~ .gfield_description{
						opacity: 1;
						visibility: visible;
					}
				}
			}

			.gfield_description{
				position: absolute;
				left: 0;
				top: calc(100% + 5px);
				bottom: auto!important;
				width: 100%!important;
				border-radius: 22px;
				border: 1px solid $blue;
				background-color: white;
				line-height: 1.4em!important;
				font-size: 1.3rem!important;
				padding: 12px;
				z-index: 1;
				opacity: 0;
				visibility: hidden;
			}


			&.addition{
				width: 46%;
				padding: 24px 75px 17px 7px;
				position: relative;

				@media (max-width: $b3){
					width: 100%;
				}

				.label{
					margin-bottom: .1em;
				}

				.value{
					@include font-size(50);
					margin-left: 10px;

					@media (max-width: $b2){
						@include font-size(25);
						margin-left: 0;
					}
				}
			}
		}
	}
}

.page-template-template-form-how-much{

	.aside-next{

		.next-to-aside{

			h3{
				color: $red;
			}

			.gform_wrapper{
				padding: 34px 0 10px;

				.btn-con{
					justify-content: space-between;
					padding: 0 0 48px;

					.btn{
						width: 48.2%;
						padding: 13px 10px;
						background-color: #E3E3E3;
						border-color: #E3E3E3;
						color: #A3A3A3!important;


						&.active{
							background-color: $red;
							border-color: $red;
							color: white!important;
						}
					}
				}
			}

			.gform_body{

				.gfield_label{
					line-height: 1.4em;
					margin-bottom: 4px;
					font-weight: bold !important;
				}

				.gfield:not(.gfield_html){
					margin-bottom: 17px;
				}

				.gfield_radio{
					padding: 12px 0 4px;

					li{

						&:first-of-type{
							margin-right: 106px;
						}
					}

				}

				.gfield_html{
					padding-left: 12px;

					h5{
						text-transform: uppercase;
						font-weight: 900;
						margin-bottom: .4em;
					}

					~ .gfield.medium.medium.medium.medium.medium:nth-child(even){
						float: left;
						clear: left;

						&.clear{
							float: left;
							clear: left;
						}
					}

					~ .gfield.medium.medium.medium.medium.medium:nth-child(odd){
						float: right;
						clear: none;

						&.clear{
							float: left;
							clear: left;
						}
					}
				}
			}

			.gform_footer button {
				width: 48.2%;
			}

			.form-notices {
				.notice {
					h3 {
						color: #434343;

						&:first-of-type {
							margin-top: 30px;
						}

						a {
							color: #47B49E;
							font-size: inherit;
						}
					}

					p {
						font-size: 16px;
					}

					.btn {
						width: 48.2%;
						padding-top: 18px;
						padding-bottom: 18px;
					}
				}
			}
		}
	}
}

.single-tenders,
.single-careers{

	.general-con .bottom-btn:nth-of-type(2){

		@media (max-width: $b3){
			flex-direction: row-reverse;
		}
	}
}

.page-template-template-form-how-much{

	.next-to-aside{

		.gfield.medium:nth-of-type(1),
		.gfield.medium:nth-of-type(2),
		.gfield.medium:nth-of-type(3),
		.gfield.medium:nth-of-type(4),
		.gfield.medium:nth-of-type(5),
		.gfield.medium:nth-of-type(6),
		.gfield.medium:nth-of-type(7){

			.gfield_label{
				font-weight: 400;
			}
		}
	}
}

.calculator-form {
	.gfield_label {
		display: inline-block;
		position: relative;

		.tooltip {
			display: block;
			background-image: url('../images/info.svg');
			background-position: center;
			background-repeat: no-repeat;
			background-size: 16px 16px;
			width: 16px;
			height: 16px;
			margin-left: 15px;
			position: absolute;
			top: 4px;
			left: 100%;
			z-index: 49;

			@media (max-width: 1250px){
				display: block;
				margin-left: 0;
				top: 0;
				position: relative;
				left: 0;
			}

			&:hover {
				cursor: pointer;

				.tooltip-content {
					opacity: 1;
					visibility: visible;
				}
			}

			.tooltip-content {
				background-color: $blue;
				min-width: 298px;
				padding: 17px 20px;
				position: absolute;
				top: 50%;
				left: 100%;
				margin-left: 16px;
				transform: translateY(-50%);
				opacity: 0;
				visibility: hidden;
				transition: all 0.2s ease-in-out;
				border-radius: 12px;

				@media (max-width: 1640px){
					min-width: 150px;
				}

				@media (max-width: 1250px){
					padding: 8px 10px;
					//top: 4px;
					left: 16px;
					//transform: none;
				}

				&:before{
					content: '';
					width: 0;
					height: 0;
					border-top: 10px solid transparent;
					border-bottom: 10px solid transparent;
					border-right:10px solid $blue;
					position: absolute;
					top: 50%;
					right: 100%;
					transform: translateY(-50%);

					@media (max-width: 1250px){
						display: none;
					}
				}

				p {
					margin: 0;
					color: #fff;
					font-size: 13px;
					font-weight: 400;
					line-height: 18px;
				}
			}
		}
	}
}

.page-id-527{

	.accordion{

		dt{
			color: $red;
		}
	}
}

.page-id-550{

	.accordion{

		dt{
			color: $green;
		}
	}
}

.aside-menu{

	.page-item-740,
	.page-item-560,
	.page-item-337,
	.page-item-335{

		> a{
			pointer-events: none;

		}
	}
}


footer{
	position: relative;

	.footer-top-outer{
		padding: 53px 0 26px;
		background-color: $red;

		@media (max-width: $b3){
			padding: 32px 0 10px;
		}

		h2,
		h3{
			color: white;
			width: 100%;
			text-align: center;
			margin-bottom: .5em;
		}

		.wrapper{
			flex-direction: column;
			align-items: center;

			@media (max-width: $b2){
				//flex-direction: column;
				//text-align: center;
				flex-wrap: wrap;
			}

			@media (max-width: $b3){
				flex-direction: column;
				text-align: left;
			}
		}
	}

	.wpcf7-not-valid.wpcf7-not-valid.wpcf7-not-valid, li.gfield.error input, .gfield_error input, .gfield_error select, .gfield_error .dk-select, .gfield_error textarea, input.mage-error, select.mage-error, textarea.mage-error{
		border-color: $red;
		box-shadow: inset 0 0 0 2px darken($red-dark, 10%) !important;
	}

	.validation_error{
		padding-left: 12px;
	}

	.wpcf7-not-valid.wpcf7-not-valid.wpcf7-not-valid:focus, input:focus, textarea:focus, select:focus{
		border-color: $green;
		box-shadow: inset 0 0 0 2px $green !important;
	}

	input[type="radio"] + .wpcf7-list-item-label:before,
	input[type="radio"] + label:before,
	input[type="checkbox"] + .wpcf7-list-item-label:before, input[type="checkbox"] + label:before{
		background-image: url(../images/checkbox-alt.svg);
	}

	.gform_wrapper.gform_wrapper .gform_heading h3{
		padding-left: 11px;

		@media (max-width: $b3){
			padding-left: 9px;
			@include font-size(20);
			margin-bottom: .7em;
		}
	}

	form{
		display: flex;
		flex-wrap: wrap;

		@media (max-width: $b3){
			flex-direction: column;

			label{
				text-align: left;
			}
		}
	}

	.gform_heading{
		width: 100%;
	}

	.gform_body{
		width: calc(100% - 389px);

		@media (max-width: 1151px){
			width: 100%;
		}

		.gform_fields .gfield.medium.medium.medium.medium.medium.medium.medium.medium{

			@media (max-width: $b3){
				float: none;
				width: 100%;
			}
		}
	}

	.gform_footer {
		margin-left: 38px;

		@media (max-width: 1151px){
			margin-left: 0;
		}

		@media (max-width: $b3){
			padding-top: 0;

			input{
				width: 100%;
			}
		}
	}

	.gform_wrapper.gform_wrapper .validation_error{
		color: white;
	}

	.gform_wrapper.gform_wrapper form .gform_fields .gfield .gfield_required{
		display: none;
	}

	*[type="submit"]{
		padding: 9.5px 28px;
		margin-top: 11px;
		min-width: 0;
	}

	.radio-checkbox{

		.gfield_label{
			display: none!important;
		}
	}

	::-webkit-input-placeholder {
		@include font-size(14);
	}

	:-moz-placeholder { /* Firefox 18- */
		@include font-size(14);
	}

	::-moz-placeholder {  /* Firefox 19+ */
		@include font-size(14);
	}

	:-ms-input-placeholder {
		@include font-size(14);
	}

	input,
	input.input-text,
	textarea,
	select{
		padding: 9px 22px;
		border-color: $red;
		margin-bottom: 12px;
		@include font-size(14);

		@media (max-width: $b3){
			margin-bottom: 24px;
		}
	}

	select{
		padding-top: 0;
		padding-bottom: 0;
		line-height: 38px;
		height: 38px;
	}

	input[type="radio"] + .wpcf7-list-item-label,
	input[type="radio"] + label,
	input[type="checkbox"] + .wpcf7-list-item-label,
	input[type="checkbox"] + label,
	label{
		color: white;
	}

	label{
		@include font-size(14);
		margin-bottom: 1px;
	}

	nav.col4,
	div.col4{
		@include font-size(14);

		p,
		h6{
			color: white;
		}

		p{
			@include font-size(14);
			@include line-height(14, 23);
			margin-bottom: 1.5em;

			a{
				@include font-size(14);
			}
		}
	}

	nav.col4{

		@media (min-width: $b2){
			width: 33.08779%;
		}

		@media (max-width: $b2){
			width: 33.3%;
		}

		@media (max-width: $b4){
			width: 100%;
		}
	}

	#gform_confirmation_message_1{
		color: white;
	}

	.social-btn{
		@include font-size(14);
		border: 1px solid white;
		font-weight: 700;
		border-radius: 30px;
		position: relative;
		text-decoration: none;
		padding: 9px 52px;
		@include inline;
		overflow: hidden;

		&:hover,
		&:focus{
			background-color: $red-dark;
		}

		.fab{
			position: absolute;
			right: 17px;
			bottom: -4px;
			@include font-size(33);
		}
	}

	div.col4.col4{
		max-width: 227px;
		justify-content: flex-end;

		@media (min-width: $b2){
			margin-left: 23px;
		}

		@media (max-width: $b2){
			width: 33.4%;
		}

		@media (max-width: $b3){
			width: 100%;
			max-width: 100%;
			margin-left: 0;
		}
	}

	.list-menu{
		list-style: none;
		flex-direction: column;

		@media (max-width: $b3){
			margin-bottom: 56px;
		}

		li{
			@include font-size(14);
			display: block;

			@media (max-width: $b2){
				@include font-size(12);
			}

			a{
				@include font-size(14);
				text-decoration: none;
				@include inline;
				padding: 4.4px 0;
				line-height: 1em;

				&:hover {
					color: #fff;
					text-decoration: underline;
				}

				@media (max-width: $b2){
					@include font-size(12);
					padding: 4px 0;
				}
			}
		}
	}

	.footer-middle-outer{
		background-color: $red;
		padding: 22px 0 36px;

		@media (max-width: $b2){
			padding: 7px 0 61px;
		}

		.flex{

			@media (max-width: $b3){
				flex-direction: column;
			}
		}

		address{
			margin-bottom: 1em;
			color: white;
		}

		a{
			color: white;

			&[href^='mailto:'],
			&[href^='tel:']{
				display: block;
				text-decoration: none;
				color: white;

				&:hover,
				&:focus{
					color: $green;
				}

				.fas{
					@include font-size(25);
					position: relative;
					top: 5px;
					margin-right: 14px;
				}
			}

			&[href^='mailto:']{
				margin-bottom: 1.1em;
			}

			&[href^='tel:']{
				margin-bottom: 3.1em;

				.fas{
					transform: rotateY(180deg) scale(.7);
					backface-visibility: visible!important;
				}
			}
		}
	}

	.under{
		width: 100%;
		display: flex;

		@media (max-width: $b3){
			flex-direction: column;
		}
	}

	#footer-logo img{

		@media (max-width: $b2){
			margin: 0 0 12px;
		}
	}

	.social-menu{
		margin-bottom: 17px;

		@media (max-width: $b2){
			text-align: left;
		}
	}

	.footer-bottom-outer{
		padding: 45px 0 53px;
		background-color: #DA3947;
		background-image: url(../images/house.svg);
		background-repeat: no-repeat;
		background-position: right calc((100vw - #{$base-width})/2) top 100%;

		@media (max-width: 1369px){
			background-position: left 91% top 100%;
		}

		@media (max-width: $b2){
			padding: 37px 0 22px;
		}

		.wrapper{
			justify-content: space-between;

			@media (max-width: $b2){
				flex-direction: column;
			}
		}
	}

	.copy{
		@include font-size(14);
		line-height: 1em;
		@include inline;
		vertical-align: middle;
		color: white;
		margin-right: 20px;
	}

	.footer-menu{
		list-style: none;
		margin-bottom: 0;

		@media (max-width: $b3){
			flex-direction: column;
			width: 100%;
		}

		li{
			@include font-size(14);
			line-height: 1em;

			@media (max-width: $b2){
				@include font-size(12);
			}

			ul{
				display: none!important;
			}

			a{
				color: white;
				@include font-size(14);
				@include inline;
				padding: 0 20px;
				text-decoration: none;

				@media (max-width: $b3){
					@include font-size(12);
					padding: 24px 0 2px;
				}

				&:hover,
				&:focus{
					color: $blue;
				}
			}
		}
	}

	.gform_wrapper.gform_wrapper form .gfield_checkbox{
		padding-left: 16px;
	}

	.footer-menu .current-menu-ancestor:not(.menu-item-type-custom),
	.footer-menu .current-page-ancestor:not(.menu-item-type-custom),
	.footer-menu .current-menu-parent:not(.menu-item-type-custom),
	.footer-menu .current_page_parent:not(.menu-item-type-custom),
	.footer-menu .current-menu-item:not(.menu-item-type-custom){

		> a{
			color: $green;
		}
	}

	.footer-trust-outer{
		padding: 9px 0;

		@media (max-width: $b3){
			padding: 24px 0 1px;
		}

		.wrapper{
			max-width: 1240px;
			align-items: center;

			@media (max-width: $b2){
				justify-content: space-between;
			}

			.our{
				color: #C9C9C9;
				font-weight: 900;
				margin-right: 37px;

				@media (max-width: $b2){
					@include font-size(10);
					margin-right: 2px;
					margin-bottom: 23px;
				}
			}

			.exce{
				color: #434343;
				font-weight: 900;
				margin-right: 38px;

				@media (max-width: $b2){
					@include font-size(10);
					margin-right: 2px;
					margin-bottom: 23px;
				}
			}

			.star{
				margin-right: 38px;

				@media (max-width: $b2){
					width: 109.93px;
					margin-right: 0;
					margin-bottom: 23px;
				}
			}

			.score{
				color: #C9C9C9;
				font-weight: 900;
				margin-right: 32px;

				@media (max-width: $b2){
					@include font-size(10);
					margin-right: 5px;
					margin-bottom: 23px;
				}

				em{
					color: #434343;
					font-weight: 900;
					font-style: normal;
				}
			}

			.trust{

				@media (max-width: $b2){
					width: 88.29px;
					margin-bottom: 23px;
				}
			}
		}
	}

	.trustpilot-widget{
		width: 100%;
	}
}

//!!BUTTON
button:focus,
.btn:focus{
	outline: none;
}

.btn-con{
	display: flex;

	.btn{
		margin: 0 2.5px 5px;

		&:first-of-type{
			margin-left: 0;
		}

		&:last-of-type{
			margin-right: 0;
		}
	}
}

.btn{
	@include inline;
	padding: 10px 20px;
	@include font-size(16);
	text-decoration: none!important;
	line-height: 1.1em;
	border: 1px solid $red;
	background-color: $red;
	color: white!important;
	border-radius: 40px;
	font-weight: 700;
	text-decoration: none;
	position: relative;
	min-width: 0;
	text-align: center;
	transition: $time;
	cursor: pointer;
	box-shadow: none;
	text-transform: none;
	font-family: $alt-font;

	&:hover,
	&:focus{
		background-color: $red-dark;
		border-color: $red-dark;
		color: white!important;
		outline: none;
	}

	@media (max-width: $b2){
		padding: 8px 15px;
	}

	&.white{
		background-color: $main-bg-colour;
		border-color: $red;
		color: $red!important;

		&:hover,
		&:focus-within{
			background-color: $red;
			color: $main-bg-colour!important;
		}
	}

	&.trans{
		background-color: transparent;
		border-color: white;

		&:hover,
		&:focus{
			background-color: rgba(white, .15);
			outline: none;
		}
	}
}
